const ActionRows = [
  { id: 1, option: "Export CSV" },
  { id: 2, option: "Import CSV" },
  { id: 3, option: "Download Template" },
];

const ColumnData = [
  {
    id: "product_name",
    align: true,
    disablePadding: false,
    label: "ProductName",
  },
  {
    id: "model_number",
    align: false,
    disablePadding: true,
    label: "Model",
  },
  {
    id: "manufacturer",
    align: true,
    disablePadding: false,
    label: "Manufacturer",
  },
  { id: "id", align: true, disablePadding: false, label: "ProductId" },
  { id: "price", align: true, disablePadding: false, label: "Price" },
];

const markAsRows = [
  { id: 0, content: "Work in Progress", value: 0 },
  { id: 1, content: "Pending", value: 1 },
  { id: 2, content: "Completed", value: 2 },
  { id: 3, content: "Cancelled", value: 3 },
  { id: 4, content: "Closed", value: 4 },
  { id: 5, content: "Waiting on Customer", value: 5 },
  { id: 6, content: "Awaiting Supplier visit", value: 6 },
  { id: 7, content: "Awaiting Feedback", value: 7 },
  { id: 8, content: "Awaiting Supplier visit", value: 8 },
];

const PDF_FONTS = [
  { id: 1, font_name: "helvetica", label: "Helvetica", font: "Helvetica" },
  { id: 2, font_name: "courier", label: "Courier", font: "Courier" },
  { id: 3, font_name: "times", label: "Times", font: "TimesRoman" },
];

const _YEARS = [
  { id: 0, name: "NONE" },
  { id: 1, name: "1 year" },
  { id: 2, name: "2 years" },
  { id: 3, name: "3 years" },
  { id: 4, name: "4 years" },
  { id: 5, name: "5 years" },
  { id: 6, name: "6 years" },
  { id: 7, name: "7 years" },
  { id: 8, name: "8 years" },
  { id: 9, name: "9 years" },
  { id: 10, name: "10 years" },
];

const Alphas = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
];

const PROPOSAL_YEARS = [
  { id: 1, name: "1 " },
  { id: 2, name: "2 " },
  { id: 3, name: "3 " },
  { id: 4, name: "4 " },
  { id: 5, name: "5 " },
];

const ONBOARDINGTITLE = (
  manager,
  integrator
) => `${manager} from ${integrator} would like to share project information on the upcoming installation works. 

Sirvez helps with transparency on all project related activities including product locations and data points such as IP, Mac, SN or any custom data point you require. Sirvez is also asset management system and helpdesk and service contract portal between you and ${integrator}

Check out the walk though video and complete the Getting Started tasks on the dashboard to familiarise yourself with the System.
`;

const COLOR_PICKERS = [
  "#ffffff",
  "#000000",
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b",
];

const XeroColumnData = [
  {
    id: "name",
    align: true,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "quote_number",
    align: false,
    disablePadding: true,
    label: "Quote Number",
  },
  {
    id: "model",
    align: false,
    disablePadding: true,
    label: "Model",
  },
  { id: "quote_id", align: true, disablePadding: false, label: "XeroID" },
  { id: "price", align: true, disablePadding: false, label: "Price" },
];

const InitialCommission = [
  { label: "ip_address", caption: "Ip address" },
  { label: "serial_number", caption: "Serial Number" },
  { label: "lan_port", caption: "Lan Port" },
  { label: "mac_address", caption: "Mac address" },
];

const ProductHeader = [
  { label: "Product Name", key: "product_name" },
  { label: "Model Number", key: "model_number" },
  { label: "Description", key: "description" },
  { label: "Manufacturer ID", key: "manufacturer" },
  { label: "Supplier ID", key: "supplier" },
  { label: "Testing Form ID", key: "test_form_id" },
  { label: "Warranty (Years)", key: "warranty_time" },
  { label: "IP", key: "ip_address" },
  { label: "SN", key: "serial_number" },
  { label: "Lan", key: "lan_port" },
  { label: "Mac", key: "mac_address" },
  { label: "Sell Price", key: "price" },
];

const IOActionRows = [
  { id: 1, option: "Import" },
  { id: 2, option: "Update All" },
  { id: 3, option: "Update New" },
  { id: 4, option: "All Products" },
];

const CurrentProductRows = [
  { id: 1, name: "Product Name", val: "product_name" },
  { id: 2, name: "Product ID", val: "id" },
  { id: 3, name: "Model", val: "model_number" },
  { id: 4, name: "Manufacturer", val: "manufacturer" },
  { id: 5, name: "Supplier", val: "supplier" },
];

const BlockingTypeRows = [
  { id: 1, caption: "Annual Leave", color: "#636363" },
  { id: 2, caption: "Training", color: "#00bcd4" },
  { id: 3, caption: "Public Holiday", color: "#26458d" },
  { id: 4, caption: "Other", color: "#e91e63" },
];

const ColorType = [
  // { id: 1, type: "Schedule", color: "#ff9800", colorid: `5` }, //orange
  { id: 2, type: "Task", color: "#03a9f4", colorid: `7` }, //light blue
  { id: 3, type: "Sub Task", color: "#e91e63", colorid: `3` }, //pink
  // { id: 4, type: "Site Survey", color: "#F44336", colorid: `6` }, //red
  // { id: 5, type: "Signed off", color: "#000000", colorid: `8` }, //black
  { id: 6, type: "Categories", color: "#4CAF50", colorid: `2` }, //green
  { id: 7, type: "Annual Leave", color: "#636363", colorid: `2` }, //light black
  { id: 8, type: "Training", color: "#00bcd4", colorid: `2` }, //cyan
  { id: 9, type: "Public Holiday", color: "#26458d", colorid: `2` }, //dark blue
  { id: 10, type: "Other", color: "#e91e63", colorid: `2` }, //pink
  { id: 11, type: "Ticket", color: "#9C27B0", colorid: `2` }, //purple
  { id: 12, type: "Delivery", color: "#2196F3", colorid: `12` }, //purple
  { id: 13, type: "Strive Engineers", color: "#3f51b5", colorid: `13` }, //primary
];

const calendar_colors = [
  { id: 1, color: "#7986CB" },
  { id: 2, color: "#33B679" },
  { id: 3, color: "#8E24AA" },
  { id: 4, color: "#E67C73" },
  { id: 5, color: "#F6BF26" },
  { id: 6, color: "#FF7043" },
  { id: 7, color: "#FAD165" },
  { id: 8, color: "#DC4E41" },
  { id: 10, color: "#00D084" },
  { id: 11, color: "#008489" },
  { id: 12, color: "#3EBCEF" },
  { id: 13, color: "#FFCC00" },
  { id: 14, color: "#F04124" },
  { id: 15, color: "#0A8043" },
  { id: 16, color: "#616161" },
  { id: 17, color: "#AA00FF" },
  { id: 18, color: "#546E7A" },
];

const TERMOPTIONS = [
  { id: 1, label: "Monthly", months: 1, value: "cpm" },
  { id: 2, label: "Quarterly", months: 3, value: "cpq" },
  { id: 3, label: "Yearly", months: 12, value: "cpy" },
];

const SignupAddDesc = [
  {
    id: 1,
    content: "Comprehensive speedy site Surveys with conditional logic forms",
  },
  {
    id: 2,
    content:
      "Track every project from first site survey to install and sign off and everything in between",
  },
  {
    id: 3,
    content:
      "Client portal with access to all live and historical projects and all products installed",
  },
  {
    id: 4,
    content:
      "Partner with contractors on projects only give access to parts of the project you want them to see",
  },
  { id: 5, content: "Real time updates between the office & field" },
  { id: 6, content: "Unlimited Sirvez support" },
];

const MONTHS = [
  { id: 1, label: "01" },
  { id: 2, label: "02" },
  { id: 3, label: "03" },
  { id: 4, label: "04" },
  { id: 5, label: "05" },
  { id: 6, label: "06" },
  { id: 7, label: "07" },
  { id: 8, label: "08" },
  { id: 9, label: "09" },
  { id: 10, label: "10" },
  { id: 11, label: "11" },
  { id: 12, label: "12" },
];

const YEARS = [
  { id: 1, label: 2020 },
  { id: 2, label: 2021 },
  { id: 3, label: 2022 },
  { id: 4, label: 2023 },
  { id: 5, label: 2024 },
  { id: 6, label: 2025 },
  { id: 7, label: 2026 },
  { id: 8, label: 2027 },
  { id: 9, label: 2028 },
  { id: 10, label: 2029 },
  { id: 11, label: 2030 },
  { id: 12, label: 2031 },
  { id: 13, label: 2032 },
  { id: 14, label: 2033 },
  { id: 15, label: 2034 },
  { id: 16, label: 2035 },
  { id: 17, label: 2036 },
  { id: 18, label: 2037 },
  { id: 19, label: 2038 },
  { id: 20, label: 2039 },
  { id: 21, label: 2040 },
];

// const Countries = [
//   { code: "AD", label: "Andorra", phone: "376" },
//   {
//     code: "AE",
//     label: "United Arab Emirates",
//     phone: "971",
//   },
//   { code: "AF", label: "Afghanistan", phone: "93" },
//   {
//     code: "AG",
//     label: "Antigua and Barbuda",
//     phone: "1-268",
//   },
//   { code: "AI", label: "Anguilla", phone: "1-264" },
//   { code: "AL", label: "Albania", phone: "355" },
//   { code: "AM", label: "Armenia", phone: "374" },
//   { code: "AO", label: "Angola", phone: "244" },
//   { code: "AQ", label: "Antarctica", phone: "672" },
//   { code: "AR", label: "Argentina", phone: "54" },
//   { code: "AS", label: "American Samoa", phone: "1-684" },
//   { code: "AT", label: "Austria", phone: "43" },
//   {
//     code: "AU",
//     label: "Australia",
//     phone: "61",
//     suggested: true,
//   },
//   { code: "AW", label: "Aruba", phone: "297" },
//   { code: "AX", label: "Alland Islands", phone: "358" },
//   { code: "AZ", label: "Azerbaijan", phone: "994" },
//   {
//     code: "BA",
//     label: "Bosnia and Herzegovina",
//     phone: "387",
//   },
//   { code: "BB", label: "Barbados", phone: "1-246" },
//   { code: "BD", label: "Bangladesh", phone: "880" },
//   { code: "BE", label: "Belgium", phone: "32" },
//   { code: "BF", label: "Burkina Faso", phone: "226" },
//   { code: "BG", label: "Bulgaria", phone: "359" },
//   { code: "BH", label: "Bahrain", phone: "973" },
//   { code: "BI", label: "Burundi", phone: "257" },
//   { code: "BJ", label: "Benin", phone: "229" },
//   { code: "BL", label: "Saint Barthelemy", phone: "590" },
//   { code: "BM", label: "Bermuda", phone: "1-441" },
//   { code: "BN", label: "Brunei Darussalam", phone: "673" },
//   { code: "BO", label: "Bolivia", phone: "591" },
//   { code: "BR", label: "Brazil", phone: "55" },
//   { code: "BS", label: "Bahamas", phone: "1-242" },
//   { code: "BT", label: "Bhutan", phone: "975" },
//   { code: "BV", label: "Bouvet Island", phone: "47" },
//   { code: "BW", label: "Botswana", phone: "267" },
//   { code: "BY", label: "Belarus", phone: "375" },
//   { code: "BZ", label: "Belize", phone: "501" },
//   {
//     code: "CA",
//     label: "Canada",
//     phone: "1",
//     suggested: true,
//   },
//   {
//     code: "CC",
//     label: "Cocos (Keeling) Islands",
//     phone: "61",
//   },
//   {
//     code: "CD",
//     label: "Congo, Democratic Republic of the",
//     phone: "243",
//   },
//   {
//     code: "CF",
//     label: "Central African Republic",
//     phone: "236",
//   },
//   {
//     code: "CG",
//     label: "Congo, Republic of the",
//     phone: "242",
//   },
//   { code: "CH", label: "Switzerland", phone: "41" },
//   { code: "CI", label: "Cote d'Ivoire", phone: "225" },
//   { code: "CK", label: "Cook Islands", phone: "682" },
//   { code: "CL", label: "Chile", phone: "56" },
//   { code: "CM", label: "Cameroon", phone: "237" },
//   { code: "CN", label: "China", phone: "86" },
//   { code: "CO", label: "Colombia", phone: "57" },
//   { code: "CR", label: "Costa Rica", phone: "506" },
//   { code: "CU", label: "Cuba", phone: "53" },
//   { code: "CV", label: "Cape Verde", phone: "238" },
//   { code: "CW", label: "Curacao", phone: "599" },
//   { code: "CX", label: "Christmas Island", phone: "61" },
//   { code: "CY", label: "Cyprus", phone: "357" },
//   { code: "CZ", label: "Czech Republic", phone: "420" },
//   {
//     code: "DE",
//     label: "Germany",
//     phone: "49",
//     suggested: true,
//   },
//   { code: "DJ", label: "Djibouti", phone: "253" },
//   { code: "DK", label: "Denmark", phone: "45" },
//   { code: "DM", label: "Dominica", phone: "1-767" },
//   {
//     code: "DO",
//     label: "Dominican Republic",
//     phone: "1-809",
//   },
//   { code: "DZ", label: "Algeria", phone: "213" },
//   { code: "EC", label: "Ecuador", phone: "593" },
//   { code: "EE", label: "Estonia", phone: "372" },
//   { code: "EG", label: "Egypt", phone: "20" },
//   { code: "EH", label: "Western Sahara", phone: "212" },
//   { code: "ER", label: "Eritrea", phone: "291" },
//   { code: "ES", label: "Spain", phone: "34" },
//   { code: "ET", label: "Ethiopia", phone: "251" },
//   { code: "FI", label: "Finland", phone: "358" },
//   { code: "FJ", label: "Fiji", phone: "679" },
//   {
//     code: "FK",
//     label: "Falkland Islands (Malvinas)",
//     phone: "500",
//   },
//   {
//     code: "FM",
//     label: "Micronesia, Federated States of",
//     phone: "691",
//   },
//   { code: "FO", label: "Faroe Islands", phone: "298" },
//   {
//     code: "FR",
//     label: "France",
//     phone: "33",
//     suggested: true,
//   },
//   { code: "GA", label: "Gabon", phone: "241" },
//   { code: "GB", label: "United Kingdom", phone: "44" },
//   { code: "GD", label: "Grenada", phone: "1-473" },
//   { code: "GE", label: "Georgia", phone: "995" },
//   { code: "GF", label: "French Guiana", phone: "594" },
//   { code: "GG", label: "Guernsey", phone: "44" },
//   { code: "GH", label: "Ghana", phone: "233" },
//   { code: "GI", label: "Gibraltar", phone: "350" },
//   { code: "GL", label: "Greenland", phone: "299" },
//   { code: "GM", label: "Gambia", phone: "220" },
//   { code: "GN", label: "Guinea", phone: "224" },
//   { code: "GP", label: "Guadeloupe", phone: "590" },
//   { code: "GQ", label: "Equatorial Guinea", phone: "240" },
//   { code: "GR", label: "Greece", phone: "30" },
//   {
//     code: "GS",
//     label: "South Georgia and the South Sandwich Islands",
//     phone: "500",
//   },
//   { code: "GT", label: "Guatemala", phone: "502" },
//   { code: "GU", label: "Guam", phone: "1-671" },
//   { code: "GW", label: "Guinea-Bissau", phone: "245" },
//   { code: "GY", label: "Guyana", phone: "592" },
//   { code: "HK", label: "Hong Kong", phone: "852" },
//   {
//     code: "HM",
//     label: "Heard Island and McDonald Islands",
//     phone: "672",
//   },
//   { code: "HN", label: "Honduras", phone: "504" },
//   { code: "HR", label: "Croatia", phone: "385" },
//   { code: "HT", label: "Haiti", phone: "509" },
//   { code: "HU", label: "Hungary", phone: "36" },
//   { code: "ID", label: "Indonesia", phone: "62" },
//   { code: "IE", label: "Ireland", phone: "353" },
//   { code: "IL", label: "Israel", phone: "972" },
//   { code: "IM", label: "Isle of Man", phone: "44" },
//   { code: "IN", label: "India", phone: "91" },
//   {
//     code: "IO",
//     label: "British Indian Ocean Territory",
//     phone: "246",
//   },
//   { code: "IQ", label: "Iraq", phone: "964" },
//   {
//     code: "IR",
//     label: "Iran, Islamic Republic of",
//     phone: "98",
//   },
//   { code: "IS", label: "Iceland", phone: "354" },
//   { code: "IT", label: "Italy", phone: "39" },
//   { code: "JE", label: "Jersey", phone: "44" },
//   { code: "JM", label: "Jamaica", phone: "1-876" },
//   { code: "JO", label: "Jordan", phone: "962" },
//   {
//     code: "JP",
//     label: "Japan",
//     phone: "81",
//     suggested: true,
//   },
//   { code: "KE", label: "Kenya", phone: "254" },
//   { code: "KG", label: "Kyrgyzstan", phone: "996" },
//   { code: "KH", label: "Cambodia", phone: "855" },
//   { code: "KI", label: "Kiribati", phone: "686" },
//   { code: "KM", label: "Comoros", phone: "269" },
//   {
//     code: "KN",
//     label: "Saint Kitts and Nevis",
//     phone: "1-869",
//   },
//   {
//     code: "KP",
//     label: "Korea, Democratic People's Republic of",
//     phone: "850",
//   },
//   { code: "KR", label: "Korea, Republic of", phone: "82" },
//   { code: "KW", label: "Kuwait", phone: "965" },
//   { code: "KY", label: "Cayman Islands", phone: "1-345" },
//   { code: "KZ", label: "Kazakhstan", phone: "7" },
//   {
//     code: "LA",
//     label: "Lao People's Democratic Republic",
//     phone: "856",
//   },
//   { code: "LB", label: "Lebanon", phone: "961" },
//   { code: "LC", label: "Saint Lucia", phone: "1-758" },
//   { code: "LI", label: "Liechtenstein", phone: "423" },
//   { code: "LK", label: "Sri Lanka", phone: "94" },
//   { code: "LR", label: "Liberia", phone: "231" },
//   { code: "LS", label: "Lesotho", phone: "266" },
//   { code: "LT", label: "Lithuania", phone: "370" },
//   { code: "LU", label: "Luxembourg", phone: "352" },
//   { code: "LV", label: "Latvia", phone: "371" },
//   { code: "LY", label: "Libya", phone: "218" },
//   { code: "MA", label: "Morocco", phone: "212" },
//   { code: "MC", label: "Monaco", phone: "377" },
//   {
//     code: "MD",
//     label: "Moldova, Republic of",
//     phone: "373",
//   },
//   { code: "ME", label: "Montenegro", phone: "382" },
//   {
//     code: "MF",
//     label: "Saint Martin (French part)",
//     phone: "590",
//   },
//   { code: "MG", label: "Madagascar", phone: "261" },
//   { code: "MH", label: "Marshall Islands", phone: "692" },
//   {
//     code: "MK",
//     label: "Macedonia, the Former Yugoslav Republic of",
//     phone: "389",
//   },
//   { code: "ML", label: "Mali", phone: "223" },
//   { code: "MM", label: "Myanmar", phone: "95" },
//   { code: "MN", label: "Mongolia", phone: "976" },
//   { code: "MO", label: "Macao", phone: "853" },
//   {
//     code: "MP",
//     label: "Northern Mariana Islands",
//     phone: "1-670",
//   },
//   { code: "MQ", label: "Martinique", phone: "596" },
//   { code: "MR", label: "Mauritania", phone: "222" },
//   { code: "MS", label: "Montserrat", phone: "1-664" },
//   { code: "MT", label: "Malta", phone: "356" },
//   { code: "MU", label: "Mauritius", phone: "230" },
//   { code: "MV", label: "Maldives", phone: "960" },
//   { code: "MW", label: "Malawi", phone: "265" },
//   { code: "MX", label: "Mexico", phone: "52" },
//   { code: "MY", label: "Malaysia", phone: "60" },
//   { code: "MZ", label: "Mozambique", phone: "258" },
//   { code: "NA", label: "Namibia", phone: "264" },
//   { code: "NC", label: "New Caledonia", phone: "687" },
//   { code: "NE", label: "Niger", phone: "227" },
//   { code: "NF", label: "Norfolk Island", phone: "672" },
//   { code: "NG", label: "Nigeria", phone: "234" },
//   { code: "NI", label: "Nicaragua", phone: "505" },
//   { code: "NL", label: "Netherlands", phone: "31" },
//   { code: "NO", label: "Norway", phone: "47" },
//   { code: "NP", label: "Nepal", phone: "977" },
//   { code: "NR", label: "Nauru", phone: "674" },
//   { code: "NU", label: "Niue", phone: "683" },
//   { code: "NZ", label: "New Zealand", phone: "64" },
//   { code: "OM", label: "Oman", phone: "968" },
//   { code: "PA", label: "Panama", phone: "507" },
//   { code: "PE", label: "Peru", phone: "51" },
//   { code: "PF", label: "French Polynesia", phone: "689" },
//   { code: "PG", label: "Papua New Guinea", phone: "675" },
//   { code: "PH", label: "Philippines", phone: "63" },
//   { code: "PK", label: "Pakistan", phone: "92" },
//   { code: "PL", label: "Poland", phone: "48" },
//   {
//     code: "PM",
//     label: "Saint Pierre and Miquelon",
//     phone: "508",
//   },
//   { code: "PN", label: "Pitcairn", phone: "870" },
//   { code: "PR", label: "Puerto Rico", phone: "1" },
//   {
//     code: "PS",
//     label: "Palestine, State of",
//     phone: "970",
//   },
//   { code: "PT", label: "Portugal", phone: "351" },
//   { code: "PW", label: "Palau", phone: "680" },
//   { code: "PY", label: "Paraguay", phone: "595" },
//   { code: "QA", label: "Qatar", phone: "974" },
//   { code: "RE", label: "Reunion", phone: "262" },
//   { code: "RO", label: "Romania", phone: "40" },
//   { code: "RS", label: "Serbia", phone: "381" },
//   { code: "RU", label: "Russian Federation", phone: "7" },
//   { code: "RW", label: "Rwanda", phone: "250" },
//   { code: "SA", label: "Saudi Arabia", phone: "966" },
//   { code: "SB", label: "Solomon Islands", phone: "677" },
//   { code: "SC", label: "Seychelles", phone: "248" },
//   { code: "SD", label: "Sudan", phone: "249" },
//   { code: "SE", label: "Sweden", phone: "46" },
//   { code: "SG", label: "Singapore", phone: "65" },
//   { code: "SH", label: "Saint Helena", phone: "290" },
//   { code: "SI", label: "Slovenia", phone: "386" },
//   {
//     code: "SJ",
//     label: "Svalbard and Jan Mayen",
//     phone: "47",
//   },
//   { code: "SK", label: "Slovakia", phone: "421" },
//   { code: "SL", label: "Sierra Leone", phone: "232" },
//   { code: "SM", label: "San Marino", phone: "378" },
//   { code: "SN", label: "Senegal", phone: "221" },
//   { code: "SO", label: "Somalia", phone: "252" },
//   { code: "SR", label: "Suriname", phone: "597" },
//   { code: "SS", label: "South Sudan", phone: "211" },
//   {
//     code: "ST",
//     label: "Sao Tome and Principe",
//     phone: "239",
//   },
//   { code: "SV", label: "El Salvador", phone: "503" },
//   {
//     code: "SX",
//     label: "Sint Maarten (Dutch part)",
//     phone: "1-721",
//   },
//   {
//     code: "SY",
//     label: "Syrian Arab Republic",
//     phone: "963",
//   },
//   { code: "SZ", label: "Swaziland", phone: "268" },
//   {
//     code: "TC",
//     label: "Turks and Caicos Islands",
//     phone: "1-649",
//   },
//   { code: "TD", label: "Chad", phone: "235" },
//   {
//     code: "TF",
//     label: "French Southern Territories",
//     phone: "262",
//   },
//   { code: "TG", label: "Togo", phone: "228" },
//   { code: "TH", label: "Thailand", phone: "66" },
//   { code: "TJ", label: "Tajikistan", phone: "992" },
//   { code: "TK", label: "Tokelau", phone: "690" },
//   { code: "TL", label: "Timor-Leste", phone: "670" },
//   { code: "TM", label: "Turkmenistan", phone: "993" },
//   { code: "TN", label: "Tunisia", phone: "216" },
//   { code: "TO", label: "Tonga", phone: "676" },
//   { code: "TR", label: "Turkey", phone: "90" },
//   {
//     code: "TT",
//     label: "Trinidad and Tobago",
//     phone: "1-868",
//   },
//   { code: "TV", label: "Tuvalu", phone: "688" },
//   {
//     code: "TW",
//     label: "Taiwan, Province of China",
//     phone: "886",
//   },
//   {
//     code: "TZ",
//     label: "United Republic of Tanzania",
//     phone: "255",
//   },
//   { code: "UA", label: "Ukraine", phone: "380" },
//   { code: "UG", label: "Uganda", phone: "256" },
//   {
//     code: "US",
//     label: "United States",
//     phone: "1",
//     suggested: true,
//   },
//   { code: "UY", label: "Uruguay", phone: "598" },
//   { code: "UZ", label: "Uzbekistan", phone: "998" },
//   {
//     code: "VA",
//     label: "Holy See (Vatican City State)",
//     phone: "379",
//   },
//   {
//     code: "VC",
//     label: "Saint Vincent and the Grenadines",
//     phone: "1-784",
//   },
//   { code: "VE", label: "Venezuela", phone: "58" },
//   {
//     code: "VG",
//     label: "British Virgin Islands",
//     phone: "1-284",
//   },
//   {
//     code: "VI",
//     label: "US Virgin Islands",
//     phone: "1-340",
//   },
//   { code: "VN", label: "Vietnam", phone: "84" },
//   { code: "VU", label: "Vanuatu", phone: "678" },
//   { code: "WF", label: "Wallis and Futuna", phone: "681" },
//   { code: "WS", label: "Samoa", phone: "685" },
//   { code: "XK", label: "Kosovo", phone: "383" },
//   { code: "YE", label: "Yemen", phone: "967" },
//   { code: "YT", label: "Mayotte", phone: "262" },
//   { code: "ZA", label: "South Africa", phone: "27" },
//   { code: "ZM", label: "Zambia", phone: "260" },
//   { code: "ZW", label: "Zimbabwe", phone: "263" },
// ];

export {
  ActionRows,
  ColumnData,
  XeroColumnData,
  InitialCommission,
  ProductHeader,
  IOActionRows,
  CurrentProductRows,
  BlockingTypeRows,
  ColorType,
  calendar_colors,
  SignupAddDesc,
  MONTHS,
  YEARS,
  _YEARS,
  PROPOSAL_YEARS,
  ONBOARDINGTITLE,
  COLOR_PICKERS,
  PDF_FONTS,
  TERMOPTIONS,
  Alphas,
  markAsRows,
};
