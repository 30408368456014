import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar'
import { useDispatch } from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { userSignOut } from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import { useHistory } from 'react-router-dom';
import { public_url } from "config";

const UserInfo = () => {

    const dispatch = useDispatch();
    const History = useHistory();

    const [anchorE1, setAnchorE1] = useState(null);
    const [open, setOpen] = useState(false);
    const [imagePreviewUrl, setProfileImgUrl] = useState('');
    const user = JSON.parse(localStorage.getItem("user"));
    useEffect(() => {
        let me = JSON.parse(localStorage.getItem("user"))
        setProfileImgUrl(me.logo_img ?
            (public_url + 'upload/img/' + me.logo_img) :
            'https://via.placeholder.com/300x300');
    }, []);
    const handleClick = event => {
        History.push('/app/settings/company/settings')
    };

    const handleRequestClose = () => {
        setOpen(false);
    };
    return (
        <div className="user-profile d-flex flex-row align-items-center">
            <Avatar
                alt='...'
                src={imagePreviewUrl}
                className="user-avatar "
            />
            <div className="user-detail">
                <div className="user-name" onClick={handleClick}>
                    <span className='font-size-18 text-wrap'>{user.co_name.length > 12 ? user.co_name.substring(0, 12) + "..." : user.co_name}</span>
                </div>
            </div>
            {/* <Menu className="user-info"
                  id="simple-menu"
                  anchorEl={anchorE1}
                  open={open}
                  onClose={handleRequestClose}
                  PaperProps={{
                      style: {
                          minWidth: 120,
                          paddingTop: 0,
                          paddingBottom: 0
                      }
                  }}
            >
                <MenuItem onClick={() => {
                    handleRequestClose();
                    History.push('/app/settings/profile');
                }}>
                    {user.profile_pic?
                    <Avatar src={`${public_url}upload/img/${user.profile_pic}`} 
                        className="rounded-circle size-20 mr-2" 
                        alt={user.first_name}
                    />
                    :<i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
                    }
                    {user.first_name} {user.last_name}
                </MenuItem>
                {user.user_type<=1?
                <MenuItem onClick={() => {
                    handleRequestClose();
                    History.push('/app/settings/company/settings');
                }}>
                    <i className="zmdi zmdi-settings zmdi-hc-fw mr-2"/>
                    {user.user_type<6?
                    (<IntlMessages id="sidebar.customers.company"/>)
                    :
                    (
                        <div>
                            {'company('}{user.company_name}{')'}
                        </div>
                    )

                    }
                    
                </MenuItem>
                :null}
                <MenuItem onClick={() => {
                    handleRequestClose();
                    dispatch(userSignOut());
                }}>
                    <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>

                    <IntlMessages id="popup.logout"/>
                </MenuItem>
            </Menu> */}
        </div>
    );
};

export default UserInfo;


