import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import asyncComponent from '../../../util/asyncComponent';

const SiteSurvey = ({match}) => (
    <Switch>
        <Route path={`${match.url}/`}
                component={asyncComponent(() => import('./routes'))}/>
    </Switch>
);

export default SiteSurvey;
