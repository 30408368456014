import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import TextField from "@material-ui/core/TextField";
//import IconButton from '@material-ui/core/IconButton';
import Button from "@material-ui/core/Button";
import { base_url, public_url, $http } from "config";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import { SignupAddDesc } from "../util/constants.js";
import Typography from "@material-ui/core/Typography";
import {
  hideMessage,
  showAuthLoader,
  /*  userFacebookSignIn,
    userGithubSignIn,
    userGoogleSignIn, */
  userSignIn,
  /* userTwitterSignIn */
} from "actions/Auth";
//import {useDropzone} from 'react-dropzone';

const SignUp = () => {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [company_name, setCompanyName] = useState("");
  const history = useHistory();
  // const [profile_pic, setProfilePic] = useState(null);
  // const [ProfilePicUrl, setProfilePicUrl] = useState(
  //   "https://via.placeholder.com/300x300"
  // );
  // const [logo_img, setLogoImg] = useState(null);
  // const [logo_imgUrl, setLogoImgUrl] = useState(
  //   "https://via.placeholder.com/300x300"
  // );

  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      if (showMessage) {
        setTimeout(() => {
          dispatch(hideMessage());
        }, 1000);
      }
      if (authUser !== null && authUser !== undefined) {
        history.push("/");
      }
    }
  }, [authUser]);

  const saveNewOnboardingCustomer = () => {
    const frmData = new FormData();

    frmData.append("firstName", first_name);
    frmData.append("lastName", last_name);
    frmData.append("company", company_name);
    frmData.append("email", email);
    frmData.append("phone", phone);
    frmData.append("password", password);

    $http
      .post(`${base_url}onboarding/addNewOnboardingCustomer`, frmData)
      .then((response) => {
        if (response.data.status === "success") {
          setIsLoading(true);
          dispatch(showAuthLoader());
          dispatch(userSignIn({ email, password }));
        } else if (response.data.status === "error") {
          NotificationManager.error(
            response.data.msg,
            "Error!",
            1000,
            () => { }
          );
        }
      })
      .catch((error) => {
        NotificationManager.error(error, "Error!", 1000, () => { });
      });
  };

  return (
    <div className="signup-main">
      <div className="d-flex flex-column w-100 h-100 modal-dialog1 justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="inline-middle mb-4">
          <img src="/sirvez-logo.png" />
          <div className="sign-up-main-title">
            Workflow Management for System Integrators
          </div>
        </div>
        <div className="row">
          <Card style={{ maxWidth: 450 }} className="col-md-6 col-6 mr-3">
            <CardContent className="d-flex flex-column align-items-center">
              <div className="sign-up-ad-header">Why Sign Up?</div>
              <div className="sign-up-ad-body">
                {SignupAddDesc.map((item) => (
                  <div key={item.id} className="d-flex mb-3">
                    <img src="/logo.png" className="form-check-img" />
                    <div className="form-list">{item.content}</div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
          <Card className="col-6 col-md-6 ml-3 pb-4">
            <CardContent>
              <div className="modal-box-content py-2 text-center">
                <Typography
                  variant="h4"
                  color="inherit"
                  noWrap
                  className="mb-3 mt-1"
                  style={{ color: "#4c4c4c" }}
                >
                  Start your Free 14 days trial
                </Typography>
                <h3 className="mb-1 text-light-grey trial-box-subtitle">
                  No obligation. No commitment.
                </h3>
                <h3 className="text-light-grey trial-box-subtitle">
                  No Credit Card Details Required.
                </h3>
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="First Name"
                  value={first_name}
                  onChange={(event) => {
                    setFirstName(event.target.value);
                  }}
                  className="mr-5"
                  required
                  fullWidth
                />
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Last Name"
                  value={last_name}
                  onChange={(event) => {
                    setLastName(event.target.value);
                  }}
                  className="mr-5"
                  required
                  fullWidth
                />
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Company Name"
                  value={company_name}
                  onChange={(event) => {
                    setCompanyName(event.target.value);
                  }}
                  required
                  fullWidth
                />
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Telephone"
                  value={phone}
                  onChange={(event) => {
                    setPhone(event.target.value);
                  }}
                  required
                  fullWidth
                />
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  required
                  fullWidth
                />
                <TextField
                  margin="dense"
                  variant="outlined"
                  label="Password"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  required
                  fullWidth
                />
              </div>
            </CardContent>
            <CardActions className="d-flex justify-content-center">
              <Button
                variant="contained"
                className="bg-success onboarding-jr-btn"
                onClick={() => saveNewOnboardingCustomer()}
                size="medium"
              >
                Start your Free Trial Now
              </Button>
            </CardActions>
          </Card>
        </div>

        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer />
      </div>
    </div>
  );
};

export default SignUp;
