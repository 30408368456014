import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button';
import {base_url, public_url, $http} from "config";
import {useHistory} from 'react-router-dom';

const NotificationItem = ({notificationList,onAppNotificationSelect}) => {
    const {id,profile_pic, notification, created_date} = notificationList;
    const History = useHistory();
    const selectItem=(index)=>{
        $http.get(base_url + "notification/readNotification",{params:{'id':index}})
        .then((response) => {
            onAppNotificationSelect();
            History.push({
                pathname: '/app/settings/notifications'               
            })
        });
    }
    return (
        <li className="media" onClick = {()=>selectItem(id)}>
            <Avatar
                alt={profile_pic}
                src={public_url + "upload/img/" + profile_pic}
                className=" mr-2"
            />
            <div className="media-body align-self-center">
                <p className="sub-heading mb-0">{notification}</p>
                <span
                className="meta-date"><small>{created_date}</small></span>
            </div>
        </li>
    );
};

export default NotificationItem;
