import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { base_url, public_url } from "../config";
import { showAuthLoader, userSignIn } from "actions/Auth";

const ReRegister = (props) => {
  const History = useHistory();
  const [email, setEmail] = useState("");
  const [user, setUser] = useState([]);
  const params = useParams();
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [profile_img, setProfileImg] = useState(
    "https://via.placeholder.com/260x260"
  );
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );
  const dispatch = useDispatch();
  useEffect(() => {
    axios
      .get(base_url + "user/checkToken", { params: { token: params.token } })
      .then((response) => {
        if (response.data.status === "success") {
          setUser(response.data.user);
          setEmail(response.data.user.email);
          setProfileImg(
            response.data.user.profile_pic
              ? public_url + "upload/img/" + response.data.user.profile_pic
              : "https://via.placeholder.com/260x260"
          );
        } else {
          History.push("/");
        }
      })
      .catch((error) => error);
  }, []);

  useEffect(() => {
    if (authUser !== null && authUser !== undefined) {
      History.push("/app/dashboard");
    }
  }, [authUser]);

  const savePassword = () => {
    if (password !== confirm) {
      NotificationManager.error(
        "The passwords you typed do not match.!",
        "error",
        3000,
        () => {}
      );
      return;
    }
    const frmData = new FormData();
    frmData.append("email", email);
    frmData.append("password", password);
    axios
      .post(base_url + "user/changePassword", frmData)
      .then((response) => {
        if (response.data.status === "success") {
          localStorage.setItem("token", response.data.data.auth_token);
          localStorage.setItem("isFirst", 0);
          localStorage.setItem("user", JSON.stringify(response.data.data));
          dispatch(userSignIn({ email, password }));

          let IO_modal = {};
          IO_modal.search_status = "sales";
          IO_modal.quote_search_key = "";
          IO_modal.intelli_products = [];
          IO_modal.sirvez_cnt = 0;
          IO_modal.io_cnt = 0;
          IO_modal.project_id = 0;
          localStorage.setItem("IO_modal", JSON.stringify(IO_modal));
          //History.push('/app/dashboard')
        } else {
          History.push("/");
        }
      })
      .catch((error) => error);
  };

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="login-content text-center">
        <div className="login-header">
          <Link className="app-logo" to="/app/app-module/login-1" title="Jambo">
            <img
              src={require("assets/images/logo_dark.png")}
              alt="jambo"
              title="jambo"
            />
          </Link>
        </div>

        <div className="login-avatar mb-4">
          <img
            className="rounded-circle size-120"
            src={profile_img}
            alt=""
            title=""
          />
        </div>
        <div className="mb-4">
          <h3>
            {user.first_name} {user.last_name}
          </h3>
        </div>
        <form method="get" action="/app/app-module/login-1">
          <div className="form-group mb-4">
            <input
              type="password"
              placeholder="New Password"
              className="form-control form-control-lg"
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          <div className="form-group mb-4">
            <input
              type="password"
              placeholder="Confirm Password"
              className="form-control form-control-lg"
              onChange={(event) => setConfirm(event.target.value)}
            />
          </div>
          <div className="form-group">
            <Button
              className="btn btn-primary jr-btn-rounded bg-primary text-white"
              variant="contained"
              onClick={() => savePassword()}
            >
              <IntlMessages id="appModule.resetPassword" />
            </Button>
          </div>
        </form>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default ReRegister;
