import React, { useState, useEffect } from "react";
import NotificationItem from "./NotificationItem";
//import {notifications} from './data';
import CustomScrollbars from "util/CustomScrollbars";
import { base_url, $http } from "config";
import { getResponsePWA } from "config";

const AppNotification = (props) => {
  const [notifications, setNotifications] = useState([]);
  const { onAppNotificationSelect } = props;
  useEffect(() => {
    init_Data();
  }, []);

  const init_Data = () => {
    getResponsePWA(
      `${base_url}notification/getNotification?isread=0`,
      updatedNotification,
      failedNotification
    );
  };

  const updatedNotification = (data) => {
    setNotifications(data.notifications);
  };

  const failedNotification = (data) => {
    console.log("**", data);
  };

  return (
    <CustomScrollbars
      className="messages-list scrollbar"
      style={{ height: 280 }}
    >
      <ul className="list-unstyled">
        {notifications.map((notification, index) => (
          <NotificationItem
            key={index}
            notificationList={notification}
            onAppNotificationSelect={onAppNotificationSelect}
          />
        ))}
      </ul>
    </CustomScrollbars>
  );
};

export default AppNotification;
