import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import axios from "axios";
import { base_url, $http, public_url } from "config";
import { useHistory, useParams } from "react-router-dom";

const Reset = (props) => {
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [not_match, setNotMatch] = useState(false);
  const [error_check_length, setErrorCheckLength] = useState(false);
  const History = useHistory();
  const params = useParams();

  useEffect(() => {
    if (confirm_password != "" && confirm_password != new_password)
      setNotMatch(true);
    else if (confirm_password == new_password) setNotMatch(false);
  }, [confirm_password]);

  const handleResetPassword = () => {
    if (new_password.length < 6) {
      setErrorCheckLength(true);
      return;
    }
    if (not_match) return;

    const frmData = new FormData();
    frmData.append("token", params.token);
    frmData.append("password", new_password);
    $http
      .post(`${base_url}customers/resetPassword`, frmData)
      .then((response) => {
        if (response.data.status == "success")
          window.location.href = `${public_url}signin`;
        else if (response.data.status == "error")
          NotificationManager.error("Error!", 1500, () => {});
      })
      .catch((error) => {
        NotificationManager.error(error, "Error!", 1000, () => {});
      });
  };

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="login-content">
        <div className="login-header">
          <Link className="app-logo" to="/" title="Jambo">
            <img
              src={require("assets/images/logo_dark.png")}
              alt="jambo"
              title="jambo"
            />
          </Link>
        </div>

        <div className="mb-4">
          <h2>
            <IntlMessages id="appModule.resetPassword" />
          </h2>
        </div>

        <div className="login-form">
          <form method="post" action="/">
            <div className="form-group mb-3">
              <TextField
                type="password"
                label="New Password"
                fullWidth
                variant="outlined"
                error={error_check_length}
                helperText={
                  error_check_length ? "You must enter 6 more letters" : ""
                }
                margin="normal"
                value={new_password}
                onChange={(event) => setNewPassword(event.target.value)}
                className="mt-0 mb-4"
              />
            </div>
            <div className="form-group mb-3">
              <TextField
                type="password"
                label="Confirm Password"
                fullWidth
                error={not_match}
                helperText={not_match ? "password don't match" : ""}
                variant="outlined"
                value={confirm_password}
                onChange={(event) => setConfirmPassword(event.target.value)}
                margin="normal"
                className="mt-0 mb-4"
              />
            </div>

            <div className="text-center">
              <Button
                className="btn btn-primary jr-btn-rounded bg-primary text-white"
                variant="contained"
                onClick={handleResetPassword}
              >
                Apply
              </Button>
            </div>
          </form>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default Reset;
