import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Collapse, List, ListItem } from "@material-ui/core";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import LockOpenRoundedIcon from "@material-ui/icons/LockOpenRounded";
import { useHistory } from "react-router-dom";
import { base_url, $http } from "config";

import IntlMessages from "../../util/IntlMessages";
import NavSection from "./NavSection";
import NavMenuItem from "./NavMenuItem";
import { getResponsePWA } from "config";

const NavCollapse = (props) => {
  const history = useHistory();

  const drawerMouseOver = useSelector(({ settings }) => settings.drawerMouseOver);
  const { name, icon, children = [] } = props;
  const isExpandable = children && children.length > 0;
  const [open, setOpen] = React.useState(false);
  const isUrl = isUrlInChildren(props, history.location.pathname);
  const nowuser = JSON.parse(localStorage.getItem("user"));
  const [service_allow, setServiceAllow] = useState("no");


  useEffect(() => {
    if (name == "sidebar.servicedesk" && nowuser.user_type != 0)
      getResponsePWA(
        `${base_url}user/serviceAllow`,
        updatedServiceAllow,
        failedServiceAllow
      );
  }, []);

  const updatedServiceAllow = (data) => {
    setServiceAllow(data.service_permission);
  };

  const failedServiceAllow = (data) => {
    console.log("error *", data);
  };

  useEffect(() => {
    if (isUrl) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isUrl]);

  // function handleClick() {
  //   if (
  //     name == "sidebar.servicedesk" &&
  //     service_allow == "no" &&
  //     nowuser.user_type != 0
  //   )
  //     setOpen(false);
  //   else setOpen(!open);
  // }
  function handleClick() {
    setOpen(!open);
  }

  history.listen((location, action) => {
    if (isUrlInChildren(props, location.pathname)) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  });

  /**
   * Check if the given url can be found
   * in one of the given parent's children
   *
   * @param parent
   * @param url
   * @returns {boolean}
   */
  function isUrlInChildren(parent, url) {
    if (!parent.children) {
      return false;
    }

    for (let i = 0; i < parent.children.length; i++) {
      if (parent.children[i].children) {
        if (isUrlInChildren(parent.children[i], url)) {
          return true;
        }
      }

      if (
        parent.children[i].link === url ||
        url.includes(parent.children[i].link)
      ) {
        return true;
      }
    }

    return false;
  }
  const MenuCollapse = (
    <ListItem className="nav-collapse-btn" button onClick={handleClick}>
      <div className="d-flex justify-content-between w-100">
        <div className="inline-middle">
          {!!icon && <i className={`sir sir-icon-${icon} font-size-20`} />}
          <span className="nav-text">
            <IntlMessages id={name} />
          </span>
        </div>
        {/* {name == "sidebar.servicedesk" && nowuser.user_type != 0 ? (
          service_allow == "no" ? (
            <div>
              <LockRoundedIcon className="size-20" />
            </div>
          ) : (
            <div>
              <LockOpenRoundedIcon className="size-20" />
            </div>
          )
        ) : (
          <div>
            {isExpandable && !open && <IconExpandMore className="nav-arrow" />}
            {isExpandable && open && <IconExpandLess className="nav-arrow" />}
          </div>
        )} */}
        <div>
          {isExpandable && !open && <IconExpandMore className="nav-arrow" />}
          {isExpandable && open && <IconExpandLess className="nav-arrow" />}
        </div>
      </div>
    </ListItem>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse
      className="nav-collapse-item main-left-sidebar"
      in={open}
      timeout="auto"
    >
      <List component="div" disablePadding>
        {children.map((item, index) => {
          switch (item.type) {
            case "section":
              return <NavSection {...item} key={index} />;
            case "collapse":
              return <NavCollapse {...item} key={index} />;
            case "item":
            default:
              return <NavMenuItem {...item} key={index} />;
          }
        })}
      </List>
    </Collapse>
  ) : null;

  return (
    <div className={`nav-collapse ${open ? "open" : ""}`}>
      {MenuCollapse}
      {MenuItemChildren}
    </div>
  );
};

export default NavCollapse;
