import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { base_url, public_url, $http } from "config";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import CardEditMenu from "./CardEditMenu";
import ReactPlayer from "react-player/lazy";
import { Modal } from "reactstrap";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Box } from "@material-ui/core";

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(8),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightBold,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const columnData = [
  { id: "step", align: "justify", disablePadding: true, label: "Step" },
  {
    id: "name",
    align: "justify",
    disablePadding: true,
    label: "Onboarding Name",
  },
  { id: "video_link", align: "justify", disablePadding: false, label: "Video" },
  { id: "", align: "justify", disablePadding: false, label: "" },
  {
    id: "btn_name",
    align: "justify",
    disablePadding: false,
    label: "Button Name",
  },
  {
    id: "btn_link",
    align: "justify",
    disablePadding: false,
    label: "Button Link",
  },
  { id: "action", align: "justify", disablePadding: false, label: "Action" },
];

//table header part
const DataTableHead = (props) => {
  const createSortHandler = (property) => (event) => {
    props.onRequestSort(event, property);
  };

  const { order, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        {columnData.map((column) => {
          return (
            <TableCell key={column.id} align={column.align}>
              <Tooltip
                title="Sort"
                placement={column.numeric ? "bottom-end" : "bottom-start"}
                enterDelay={300}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={order}
                  onClick={createSortHandler(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const useStyles = makeStyles({
  videoBox: {
    pointerEvents: "none",
    "&:hover": {
      background: "#e8eaf6",
    },
  },
});

DataTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
};

const OnboardingTable = (props) => {
  const {
    loader,
    goback,
    removeOnboard,
    handleClickOnboard,
    Onboardings,
  } = props;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState();
  const { width } = useSelector(({ settings }) => settings);
  const [show_video, setShowVideo] = useState(false);
  const [cur_video, setCurVideo] = useState("");
  const [tab_val, setTabVal] = useState(0);
  const [onboardings, setOnboardings] = useState([]);
  const History = useHistory();
  //sort tabel
  const handleRequestSort = (event, property) => {
    let sort_order;
    if (orderBy === property && order === "asc") {
      sort_order = "desc";
    } else {
      sort_order = "asc";
    }
    sort_order === "desc"
      ? onboardings.sort((a, b) => (b[property] < a[property] ? -1 : 1))
      : onboardings.sort((a, b) => (a[property] < b[property] ? -1 : 1));
    setOrder(sort_order);
    setOrderBy(property);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setOnboardings(Onboardings.filter((item) => item.user_type == tab_val));
    }
    // return () => {
    //   cleanup
    // }
    return () => {
      isMounted = false;
    };
  }, [Onboardings]);

  const handleTabChange = (event, newVal) => {
    setTabVal(newVal);
    setOnboardings([...Onboardings.filter((item) => item.user_type == newVal)]);
  };

  const handleShowVideo = (event, url) => {
    event.stopPropagation();
    setCurVideo(url);
    setShowVideo(true);
  };

  const onSelectOnboard = (data, index) => {
    if (index == 0) {
      handleClickOnboard(onboardings.find((item) => item.id == data));
    } else if (index == 1) {
      const frmData = new FormData();
      frmData.append("id", data);
      frmData.append("delete", "del");
      $http
        .post(`${base_url}notification/handleOnboarding`, frmData)
        .then((response) => {
          if (response.data.status === "success") {
            removeOnboard(data);
            NotificationManager.success("Success!", 1000, () => { });
            //this.init_Data()
          } else if (response.data.status === "error") {
            NotificationManager.error("Error!", 1000, () => { });
          }
        })
        .catch((error) => {
          NotificationManager.error(error, "Error!", 1000, () => { });
        });
    }
  };

  return loader ? (
    <div>
      <div className="flex-auto">
        <Box>
          <AntTabs value={tab_val} onChange={handleTabChange}>
            <AntTab label="Integrator" />
            <AntTab label="End User" />
          </AntTabs>
        </Box>
        <div className="table-responsive-material">
          <Table className="">
            <DataTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {onboardings.map((n) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={n.id}
                    onClick={() => handleClickOnboard(n)}
                  >
                    <TableCell>{n.step}</TableCell>
                    <TableCell>{n.name}</TableCell>
                    <TableCell>{n.video_link}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(event) =>
                          handleShowVideo(event, n.video_link)
                        }
                      >
                        View
                      </Button>
                    </TableCell>
                    <TableCell>{n.btn_name}</TableCell>
                    <TableCell>{n.btn_link}</TableCell>
                    <TableCell
                      key={n.id}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <CardEditMenu
                        key={n.id}
                        selectedData={n.id}
                        onSelectRow={onSelectOnboard}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
      <Modal
        id="noti-modal"
        className="comment_modal modal-box noti-modal"
        toggle={() => setShowVideo(false)}
        isOpen={show_video}
      >
        <div className="row">
          <div className="col-12">
            <ReactPlayer
              url={cur_video}
              className="react-player"
              controls={true}
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </Modal>
      <NotificationContainer />
    </div>
  ) : (
    <div
      className="loader-view"
      style={{
        height: width >= 1200 ? "calc(100vh - 259px)" : "calc(100vh - 238px)",
      }}
    >
      <CircularProgress />
      <SweetAlert
        show={goback}
        warning
        confirmBtnText="Go Back"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Warning!"
        onConfirm={() => History.go(-1)}
      >
        Data not cached, so you can not view this page.
      </SweetAlert>
    </div>
  );
};

export default OnboardingTable;
