import React from 'react';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';

const getDisplayString = (sub) => {
    const arr = sub.split("-");
    if (arr.length > 1) {
        return arr[0].charAt(0).toUpperCase() + arr[0].slice(1) + " " + arr[1].charAt(0).toUpperCase() + arr[1].slice(1)
    } else {
        return sub.charAt(0).toUpperCase() + sub.slice(1)
    }

};
const getUrlString = (path, sub, index) => {
    if (index === 0) {
        return '/';
    } else {
        return '/' + path.split(sub)[0] + sub;
    }
};

const ContainerHeader = ({title, match,project_name,room_number,group_name,product_name}) => {
    localStorage.setItem('current_url',match.url)
    let url_name = [];
    const path = match.url.substr(1);
    const subPath = path.split('/');
    subPath.map((sub,index)=>{
        if(sub===''){
            subPath.splice(index,1)
        }
        if(subPath[index-1] ==='live'||subPath[index-1] ==='service')
            url_name[index] = project_name?project_name:''
        else if(subPath[index-2] ==='live'||subPath[index-1] ==='service')
            url_name[index] = room_number?room_number:''
        else if(subPath[index-3] ==='live'||subPath[index-1] ==='service')
            url_name[index] = group_name?group_name:''
        else if(subPath[index-4] ==='live'||subPath[index-1] ==='service')
            url_name[index] = product_name?product_name:''
        else
            url_name[index] = sub
    })
    return (
        <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center mb-0">
            <h2 className="title mb-sm-0">{title}</h2>

            <Breadcrumb className="mb-0" tag="nav">
                {subPath.map((sub, index) => {
                        if(index ==0) return;
                        return <BreadcrumbItem active={subPath.length === index + 1}
                                            tag={subPath.length === index + 1 ? "span" : "a"} key={index}
                                            href={getUrlString(path, sub, index)}>{getDisplayString(url_name[index])}</BreadcrumbItem>
                    }
                )}
            </Breadcrumb>
        </div>
    )
};

export default ContainerHeader;

