import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import Countdown from "react-countdown";
import { Button, Tooltip, Avatar, Typography } from "@material-ui/core";
import { base_url, public_url, $http } from "config";
import { Helmet } from "react-helmet";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { render } from "@testing-library/react";
import moment from "moment";
import { StringToColor } from "util/functions";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
  },
}))(Tooltip);


const Completionist = () => <span>You are good to go!</span>;
const renderer = ({ days, completed }) => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (completed) {
    // Render a completed state
    // return <Completionist />;
    return <></>;
  } else {
    // Render a countdown
    return (
      <div className="inline-middle justify-content-center m-4 bg-black rounded p-3">
        <Avatar
          className="size-40"
          alt={user.first_name}
          src={`${public_url}upload/img/${user.profile_pic}`}
        />
        <div className="ml-2">
          <h3 className="text-white mb-0">{`Trial: ${days} ${days == 1 ? "day" : "days"
            }`}</h3>
          <span>Remaining</span>
        </div>
      </div>
    );
  }
};

const OnlineUsers = (props) => {
  //const {onlineUsers} = useSelector(({settings}) => settings);
  const onlineUsers = localStorage.hasOwnProperty("onlineUsers")
    ? JSON.parse(localStorage.getItem("onlineUsers"))
    : [];
  const [open, setOpen] = useState(false);
  const [livechat_open, setLivechatOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  function CharAt(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  return (
    <div>
      {user.expired_date != 0 && (
        <Countdown date={new Date(user.expired_date)} renderer={renderer} />
      )}
      <div className="m-4">
        <div className="font-size-20 mb-1 px-3">Who's online?</div>
        <div className="bg-black rounded p-3">
          <div>
            <div className="inline-middle m-2 font-size-15">
              <i className="zmdi zmdi-accounts-alt mr-2" />
              Users
            </div>
            <div className="d-flex-stretch">
              {onlineUsers &&
                onlineUsers
                  .filter(
                    (item) =>
                      item.id != user.id && item.company_id == user.company_id
                  )
                  .map((user) => (
                    <div key={user.id} className="chat-avatar-mode mb-1 mx-2">
                      {/* <HtmlTooltip
                        title={
                          <Fragment>
                            <div className="">
                              <strong>{CharAt(user.first_name)}</strong>{" "}
                              <strong>{CharAt(user.last_name)}</strong> from{" "}
                              <em>{user.company_name}</em> has been online since{" "}
                              {moment(user.login_date).format("MMM Do, h a")}(
                              {moment(user.login_date).fromNow()})
                            </div>
                          </Fragment>
                        }
                      >
                        <Avatar
                          src={`${public_url}upload/img/${user.profile_pic}`}
                          className="rounded-circle"
                          children={user.first_name.charAt(0) + user.last_name.charAt(0)}
                          style={{
                            background: user.profile_pic ? "transparent" : StringToColor(user.first_name)
                          }}
                          variant="rounded"
                        />
                      </HtmlTooltip> */}
                      <Avatar
                        src={`${public_url}upload/img/${user.profile_pic}`}
                        className="rounded-circle"
                        children={user.first_name.charAt(0) + user.last_name.charAt(0)}
                        style={{
                          background: user.profile_pic ? "transparent" : StringToColor(user.first_name)
                        }}
                        variant="rounded"
                      />
                      <span
                        className={`chat-mode small ${user.status > 0 ? "online" : "away"
                          }`}
                      />
                    </div>
                  ))}
            </div>
          </div>

          {/* {onlineUsers.filter(
            (item) =>
              item.id != user.id &&
              item.user_type > 4 &&
              item.com_id == user.com_id
          ).length > 0 && (
            <div>
              <div className="inline-middle m-2 font-size-15">
                <i className="zmdi zmdi-accounts-alt mr-2" />
                Customers
              </div>
              <div className="d-flex-stretch">
                {onlineUsers &&
                  onlineUsers
                    .filter(
                      (item) =>
                        item.id != user.id &&
                        item.user_type > 4 &&
                        item.com_id == user.com_id
                    )
                    .map((user) => (
                      <div key={user.id} className="chat-avatar-mode mb-1 mx-2">
                        <HtmlTooltip
                          title={
                            <Fragment>
                              <div>
                                <strong>{CharAt(user.first_name)}</strong>{" "}
                                <strong>{CharAt(user.last_name)}</strong> from{" "}
                                <em>{user.company_name}</em> has been online
                                since{" "}
                                {moment(user.login_date).format("MMM Do, h a")}(
                                {moment(user.login_date).fromNow()})
                              </div>
                            </Fragment>
                          }
                        >
                          <Avatar
                            src={`${public_url}upload/img/${user.profile_pic}`}
                            className="rounded-circle"
                            children={`${user.first_name
                              .charAt(0)
                              .toUpperCase()}${user.last_name
                              .charAt(0)
                              .toUpperCase()}`}
                            style={{
                              background: StringToColor(user.first_name),
                            }}
                          />
                        </HtmlTooltip>
                        <span
                          className={`chat-mode small ${
                            user.status > 0 ? "online" : "away"
                          }`}
                        />
                      </div>
                    ))}
              </div>
            </div>
          )} */}

          {/* {livechat_open && (
            <Helmet>
              <script>
                {`!(function(e, t) {
        if (!e.groove) {
          var i = function(e, t) {
              return Array.prototype.slice.call(e, t);
            },
            a = {
              widget: null,
              loadedWidgets: {},
              classes: {
                Shim: null,
                Embeddable: function() {
                  (this._beforeLoadCallQueue = []),
                    (this.shim = null),
                    (this.finalized = !1);
                  var e = function(e) {
                    var t = i(arguments, 1);
                    if (this.finalized) {
                      if (!this[e])
                        throw new TypeError(
                          e + "() is not a valid widget method"
                        );
                      this[e].apply(this, t);
                    } else this._beforeLoadCallQueue.push([e, t]);
                  };
                  (this.initializeShim = function() {
                    a.classes.Shim && (this.shim = new a.classes.Shim(this));
                  }),
                    (this.exec = e),
                    (this.init = function() {
                      e.apply(this, ["init"].concat(i(arguments, 0))),
                        this.initializeShim();
                    }),
                    (this.onShimScriptLoad = this.initializeShim.bind(this)),
                    (this.onload = void 0);
                },
              },
              scriptLoader: {
                callbacks: {},
                states: {},
                load: function(e, i) {
                  if ("pending" !== this.states[e]) {
                    this.states[e] = "pending";
                    var a = t.createElement("script");
                    (a.id = e),
                      (a.type = "text/javascript"),
                      (a.async = !0),
                      (a.src = i);
                    var s = this;
                    a.addEventListener(
                      "load",
                      function() {
                        (s.states[e] = "completed"),
                          (s.callbacks[e] || []).forEach(function(e) {
                            e();
                          });
                      },
                      !1
                    );
                    var n = t.getElementsByTagName("script")[0];
                    n.parentNode.insertBefore(a, n);
                  }
                },
                addListener: function(e, t) {
                  "completed" !== this.states[e]
                    ? (this.callbacks[e] || (this.callbacks[e] = []),
                      this.callbacks[e].push(t))
                    : t();
                },
              },
              createEmbeddable: function() {
                var t = new a.classes.Embeddable();
                return e.Proxy
                  ? new Proxy(t, {
                      get: function(e, t) {
                        return e instanceof a.classes.Embeddable
                          ? Object.prototype.hasOwnProperty.call(e, t) ||
                            "onload" === t
                            ? e[t]
                            : function() {
                                e.exec.apply(e, [t].concat(i(arguments, 0)));
                              }
                          : e[t];
                      },
                    })
                  : t;
              },
              createWidget: function() {
                var e = a.createEmbeddable();
                return (
                  a.scriptLoader.load(
                    "groove-script",
                    "https://e7404802-e426-41d2-a0e6-4a31da588d18.widget.cluster.groovehq.com/api/loader"
                  ),
                  a.scriptLoader.addListener(
                    "groove-iframe-shim-loader",
                    e.onShimScriptLoad
                  ),
                  e
                );
              },
            };
          e.groove = a;
        }
      })(window, document);
      window.groove.widget = window.groove.createWidget();
      window.groove.widget.init("e7404802-e426-41d2-a0e6-4a31da588d18", {});`}
              </script>
            </Helmet>
          )} */}
        </div>
      </div>
      {/* <div className="bg-black rounded p-2 m-4 text-center">
        <Button
          startIcon={<img src="/chat.png" />}
          size="large"
          onClick={() => setLivechatOpen(true)}
          className="text-white text-capitalize"
          style={{ fontSize: 20 }}
        >
          Live Chat
        </Button>
      </div> */}
    </div>
  );
};
export default OnlineUsers;
