import React, { useState, useEffect } from "react";
import ContainerHeader from "components/ContainerHeader";
import IntlMessages from "util/IntlMessages";
import CardBox from "components/CardBox";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import { Card, CardActions, CardContent } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import PersistentOnboarding from "./PersistentOnboarding";
import { base_url, public_url, $http } from "config";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IntegrationReactMultiSelect from "components/autoComplete/reactSelect/IntegrationReactMultiSelect";
import IntegrationReactOutlineMultiSelect from "components/autoComplete/reactSelect/IntegrationReactOutlineMultiSelect";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Modal, ModalHeader } from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import { useDropzone } from "react-dropzone";
import Paper from "@material-ui/core/Paper";

import Checkbox from "@material-ui/core/Checkbox";
import { getResponsePWA } from "config";

const Onboarding = (props) => {
  const [new_onboard_modal, setNewOnboardModal] = useState(false);

  const [onboardings, setOnboardings] = useState([]);
  const [loader, setLoader] = useState(false);
  const [goback, setGoback] = useState(false);

  const [add_new_modal, setAddNewModal] = useState(false);

  const [new_onboard, setNewOnboard] = useState({
    id: 0,
    name: "",
    step: "",
    video_link: "",
    btn_link: "",
    btn_name: "",
    user_type: "integrator",
  });

  const CloseOnboardingModal = () => {
    setNewOnboardModal(false);
  };

  useEffect(() => {
    initData();
  }, []);

  const removeOnboard = (id) => {
    const temp_noties = onboardings.filter((item) => item.id != id);
    setOnboardings(temp_noties);
  };

  const initNewOnboard = () => {
    setNewOnboard({
      ...new_onboard,
      id: 0,
      name: "",
      step: "",
      video_link: "",
      btn_link: "",
      btn_name: "",
      user_type: "integrator",
    });
  };

  const initData = () => {
    getResponsePWA(
      `${base_url}notification/onboardingList`,
      updatedOnboarding,
      failedOnboarding
    );
  };

  const updatedOnboarding = (data) => {
    setOnboardings(data.onboardings);
    setLoader(true);
  };

  const failedOnboarding = (data) => {
    setGoback(true);
  };

  const saveOnboard = () => {
    const frmData = new FormData();
    if (add_new_modal) frmData.append("add", "add");
    else frmData.append("update", "update");

    let temp_onboard = new_onboard;
    temp_onboard.user_type = new_onboard.user_type == "integrator" ? 0 : 1;
    frmData.append("onboard", JSON.stringify(temp_onboard));
    setNewOnboardModal(false);

    $http
      .post(`${base_url}notification/handleOnboarding`, frmData)
      .then((response) => {
        if (response.data.status === "success") {
          NotificationManager.success(
            `You ${
              add_new_modal ? "added new" : "updated"
            } Onboard successfully`,
            "Success!",
            1500,
            () => {}
          );
          initNewOnboard();
          initData();
        } else if (response.data.status === "error") {
          NotificationManager.error(
            response.data.msg,
            "Error!",
            1000,
            () => {}
          );
        }
      })
      .catch((error) => {
        NotificationManager.error(error, "Error!", 1000, () => {});
      });
  };

  const new_rows = [
    { id: 1, name: "User Type", key: "" },
    { id: 2, name: "Step Name", key: "name" },
    { id: 3, name: "Video Link", key: "video_link" },
    { id: 4, name: "Button Link", key: "btn_link" },
    { id: 5, name: "Button Name", key: "btn_name" },
    { id: 6, name: "Step", key: "step" },
  ];

  const handleClickOnboard = (data) => {
    setNewOnboardModal(true);
    setAddNewModal(false);
    setNewOnboard({
      ...new_onboard,
      id: data.id,
      name: data.name,
      step: data.step,
      video_link: data.video_link,
      btn_link: data.btn_link,
      btn_name: data.btn_name,
      user_type: data.user_type == 0 ? "integrator" : "end_user",
    });
  };

  return (
    <div className="app-wrapper">
      <ContainerHeader
        match={props.match}
        title={<IntlMessages id="sidebar.onboarding" />}
      />
      <div className="text-right">
        <Tooltip title="New Notification">
          <IconButton
            aria-label="New Notification"
            onClick={() => {
              initNewOnboard();
              setNewOnboardModal(true);

              setAddNewModal(true);
            }}
          >
            <i className="zmdi zmdi-plus-circle text-primary"></i>
          </IconButton>
        </Tooltip>
      </div>
      <div className="row">
        <CardBox styleName="col-12" cardStyle="p-0">
          <PersistentOnboarding
            onboardings={onboardings}
            loader={loader}
            goback={goback}
            handleClickOnboard={handleClickOnboard}
            initData={initData}
            removeOnboard={removeOnboard}
          />
        </CardBox>
      </div>

      <Modal
        key="customer-save"
        className="modal-box"
        toggle={CloseOnboardingModal}
        isOpen={new_onboard_modal}
      >
        <ModalHeader
          className={`text-white ${
            add_new_modal ? "bg-primary" : "bg-secondary"
          }`}
        >
          {`${add_new_modal ? "Add New Notification" : "Modify Notification"}`}
          <IconButton className="text-grey py-0" onClick={CloseOnboardingModal}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>
        <div className="modal-box-content row">
          <div className="col-12">
            <Card>
              <CardContent>
                <Paper variant="outlined" className="p-3">
                  {new_rows.map((item) => (
                    <div key={item.id} className="row">
                      <div className="col-12 col-md-8">
                        <div className="row align-items-center">
                          <div className="col-3 text-right">
                            <h5 className="mb-0">{item.name}</h5>
                          </div>
                          <div className="col-9">
                            {item.id == 1 ? (
                              <FormControl component="fieldset" required>
                                <RadioGroup
                                  className="d-flex flex-row"
                                  value={new_onboard["user_type"]}
                                  onChange={(event, value) =>
                                    setNewOnboard({
                                      ...new_onboard,
                                      user_type: value,
                                    })
                                  }
                                >
                                  <FormControlLabel
                                    value="integrator"
                                    control={<Radio color="primary" />}
                                    disabled={!add_new_modal ? true : false}
                                    label="Integrator"
                                  />
                                  <FormControlLabel
                                    value="end_user"
                                    control={<Radio color="primary" />}
                                    disabled={!add_new_modal ? true : false}
                                    label="End User"
                                  />
                                </RadioGroup>
                              </FormControl>
                            ) : (
                              <TextField
                                id="name"
                                variant="outlined"
                                required
                                value={new_onboard[item.key]}
                                onChange={(event) => {
                                  setNewOnboard({
                                    ...new_onboard,
                                    [item.key]: event.target.value,
                                  });
                                  if (!add_new_modal) {
                                    let temps = onboardings;
                                    temps
                                      .filter(
                                        (option) =>
                                          option.id == new_onboard["id"]
                                      )
                                      .map(
                                        (option) =>
                                          (option[item.key] =
                                            event.target.value)
                                      );
                                    setOnboardings(temps);
                                  }
                                }}
                                margin="dense"
                                fullWidth
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Paper>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color={`${add_new_modal ? "primary" : "secondary"}`}
                  onClick={() => saveOnboard()}
                  className="ml-auto mr-2 mb-2 px-5"
                >
                  Save
                </Button>
              </CardActions>
            </Card>
          </div>
        </div>
      </Modal>

      <NotificationContainer />
    </div>
  );
};

export default Onboarding;
