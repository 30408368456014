import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { base_url, public_url, $http } from "config";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import CardEditMenu from "./CardEditMenu";
import { Form } from "reactstrap";

const columnData = [
  {
    id: "name",
    align: "justify",
    disablePadding: true,
    label: "Notification Name",
  },
  {
    id: "assign_pages",
    align: "justify",
    disablePadding: false,
    label: "Pages",
  },
  { id: "type", align: "justify", disablePadding: false, label: "Type" },
  { id: "video_url", align: "justify", disablePadding: false, label: "Video" },
  {
    id: "display_from",
    align: "justify",
    disablePadding: false,
    label: "Display From",
  },
  {
    id: "display_to",
    align: "justify",
    disablePadding: false,
    label: "Display Till",
  },
  { id: "preview", align: "justify", disablePadding: false, label: "" },
  { id: "status", align: "justify", disablePadding: false, label: "Status" },
  { id: "action", align: "justify", disablePadding: false, label: "Action" },
];

//table header part
const DataTableHead = (props) => {
  const createSortHandler = (property) => (event) => {
    props.onRequestSort(event, property);
  };

  const { order, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        {columnData.map((column) => {
          return (
            <TableCell key={column.id} align={column.align}>
              <Tooltip
                title="Sort"
                placement={column.numeric ? "bottom-end" : "bottom-start"}
                enterDelay={300}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={order}
                  onClick={createSortHandler(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

DataTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
};

const NotificationTable = (props) => {
  const {
    notifications,
    loader,
    goback,
    removeNoti,
    notificationPages,
    handleClickNoti,
  } = props;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { width } = useSelector(({ settings }) => settings);
  const History = useHistory();
  //sort tabel
  const handleRequestSort = (event, property) => {
    let sort_order;
    if (orderBy === property && order === "asc") {
      sort_order = "desc";
    } else {
      sort_order = "asc";
    }
    sort_order === "desc"
      ? notifications.sort((a, b) => (b[property] < a[property] ? -1 : 1))
      : notifications.sort((a, b) => (a[property] < b[property] ? -1 : 1));
    setOrder(sort_order);
    setOrderBy(property);
  };
  //page change
  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const nowuser = JSON.parse(localStorage.getItem("user"));

  const onSelectNoti = (data, index) => {
    if (index == 0) {
      handleClickNoti(notifications.find((item) => item.id == data));
    } else if (index == 1) {
      const frmData = new FormData();
      frmData.append("id", data);
      $http
        .post(`${base_url}notification/delUserNotification`, frmData)
        .then((response) => {
          if (response.data.status === "success") {
            removeNoti(data);
            NotificationManager.success("Success!", 1000, () => {});
            //this.init_Data()
          } else if (response.data.status === "error") {
            NotificationManager.error("Error!", 1000, () => {});
          }
        })
        .catch((error) => {
          NotificationManager.error(error, "Error!", 1000, () => {});
        });
    }
  };

  return loader ? (
    <div>
      <div className="flex-auto">
        <div className="table-responsive-material">
          <Table className="">
            <DataTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {notifications
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={n.id}
                      onClick={() => handleClickNoti(n)}
                    >
                      {columnData.map((item) => {
                        let pages = "";
                        let len = n["assign_pages"].length;
                        if (
                          (n["onboarding"] != 2 && len == 8) ||
                          (n["onboarding"] == 2 && len == 4)
                        )
                          pages = "All";
                        else
                          n["assign_pages"].map((item, index) => {
                            if (index == len - 1)
                              pages = pages + notificationPages[item].page_name;
                            else
                              pages =
                                pages + notificationPages[item].page_name + ",";
                          });

                        if (item.id == "assign_pages")
                          return <TableCell key={item.id}>{pages}</TableCell>;
                        else if (item.id == "video_url")
                          return (
                            <TableCell key={item.id}>
                              {n[item.id] ? "Yes" : "No"}
                            </TableCell>
                          );
                        else if (
                          item.id == "display_from" ||
                          item.id == "display_to"
                        )
                          return (
                            <TableCell key={item.id}>
                              {n[item.id] ? n[item.id] : "-"}
                            </TableCell>
                          );
                        else if (item.id == "preview")
                          return (
                            <TableCell key={item.id}>
                              <Button
                                className="jr-btn"
                                variant="contained"
                                color="primary"
                              >
                                Preview
                              </Button>
                            </TableCell>
                          );
                        else if (item.id == "status")
                          return (
                            <TableCell key={item.id}>
                              <div className="badge bg-success text-uppercase text-white">
                                {n[item.id]}
                              </div>
                            </TableCell>
                          );
                        else if (item.id == "action")
                          return (
                            <TableCell
                              key={item.id}
                              onClick={(event) => event.stopPropagation()}
                            >
                              <CardEditMenu
                                key={item.id}
                                selectedData={n.id}
                                onSelectRow={onSelectNoti}
                              />
                            </TableCell>
                          );
                        else
                          return (
                            <TableCell
                              key={item.id}
                              className={`${item.id == "name" ? "pl-4" : ""}`}
                            >
                              {n[item.id]}
                            </TableCell>
                          );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={notifications.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </div>
      <NotificationContainer />
    </div>
  ) : (
    <div
      className="loader-view"
      style={{
        height: width >= 1200 ? "calc(100vh - 259px)" : "calc(100vh - 238px)",
      }}
    >
      <CircularProgress />
      <SweetAlert
        show={goback}
        warning
        confirmBtnText="Go Back"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Warning!"
        onConfirm={() => History.go(-1)}
      >
        Data not cached, so you can not view this page.
      </SweetAlert>
    </div>
  );
};

export default NotificationTable;
