import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import IntlMessages from "util/IntlMessages";
import { base_url, public_url, $http } from "config";
import { useSelector } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";

const Companylogin = (props) => {
  const params = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logo_img, setLogoImg] = useState(public_url + "upload/img/logo.png");
  const [back_img, setBackImg] = useState(
    public_url + "upload/img/default_companybg.jpeg"
  );
  const [loader, setLoader] = useState(false);
  const { width } = useSelector(({ settings }) => settings);
  const History = useHistory();

  const userSignIn = () => {
    const user = { email: email, password: password };
    return new Promise((resolve, reject) => {
      axios
        .get(base_url + "user/login", {
          params: { email: email, password: password },
        })
        .then((response) => {
          if (response.data.status === "success") {
            localStorage.setItem("token", response.data.data.auth_token);
            localStorage.setItem("user", JSON.stringify(response.data.data));
            localStorage.setItem("user_id", email);
            resolve(user);
            History.push("/app/project");
          } else {
            resolve({ message: response.data.msg });
          }
        })
        .catch((error) => error);
    });
  };

  useEffect(() => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("user");
    localStorage.removeItem("token");

    $http
      .get(base_url + "company/getCompanyImg", {
        params: { company_name: params.company_name },
      })
      .then((response) => {
        response.data.company.logo_img &&
          setLogoImg(
            public_url + "upload/img/" + response.data.company.logo_img
          );
        response.data.company.bg_image &&
          setBackImg(
            public_url + "upload/img/" + response.data.company.bg_image
          );
        setLoader(true);
      })
      .catch((error) => {
        setLoader(true);
      });
  }, []);

  return loader ? (
    <div className="container-login100">
      <div className="login100-more">
        <img
          src={back_img}
          style={{ width: "100%", height: "100vh", opacity: 0.3 }}
        />
      </div>
      <div className="wrap-login100">
        <div className="login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          <div className="login-content ">
            <div className="login-header">
              <img
                src={logo_img}
                alt="jambo"
                title="jambo"
                className="rounded-xl"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="login-form">
              <form>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="appModule.email" />}
                    fullWidth
                    onChange={(event) => setEmail(event.target.value)}
                    defaultValue={email}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password" />}
                    fullWidth
                    onChange={(event) => setPassword(event.target.value)}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        userSignIn();
                      }
                    }}
                    defaultValue={password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 inline-middle justify-content-between">
                    <Button
                      onClick={() => userSignIn()}
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="appModule.signIn" />
                    </Button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
          <NotificationContainer />
        </div>
      </div>
    </div>
  ) : (
    <div
      className="loader-view"
      style={{
        height: width >= 1200 ? "calc(100vh - 259px)" : "calc(100vh - 238px)",
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default Companylogin;
