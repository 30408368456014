import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { NotificationManager } from "react-notifications";
// import {
//     auth
//     facebookAuthProvider,
//     githubAuthProvider,
//     googleAuthProvider,
//     twitterAuthProvider
// } from 'firebase/app';

import {
  /*SIGNIN_FACEBOOK_USER,
    SIGNIN_GITHUB_USER,
    SIGNIN_GOOGLE_USER,
    SIGNIN_TWITTER_USER, */
  SIGNIN_USER,
  //SIGNOUT_USER,
  SIGNUP_USER,
} from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignUpSuccess,
} from "actions/Auth";
/* import {
    userFacebookSignInSuccess,
    userGithubSignInSuccess,
    userGoogleSignInSuccess,
    userTwitterSignInSuccess
} from "../actions/Auth"; */

import axios from "axios";
import { base_url } from "../config";

const createUserWithEmailPasswordRequest = async (
  email,
  password,
  company_name,
  user_name,
  website,
  company_email,
  address,
  city,
  postcode,
  first_name,
  last_name,
  job_title,
  mobile,
  profile_pic,
  logo_img
) => {
  const user = {
    email: email,
    password: password,
    company_name: company_name,
    user_name: user_name,
    website: website,
    company_email: company_email,
    address: address,
    city: city,
    postcode: postcode,
    first_name: first_name,
    last_name: last_name,
    job_title: job_title,
    mobile: mobile,
    profile_pic: profile_pic,
    logo_img: logo_img,
  };
  const userData = new FormData();
  userData.append("email", email);
  userData.append("password", password);
  userData.append("company_name", company_name);
  userData.append("user_name", user_name);
  userData.append("website", website);
  userData.append("company_email", company_email);
  userData.append("address", address);
  userData.append("city", city);
  userData.append("postcode", postcode);
  userData.append("first_name", first_name);
  userData.append("last_name", last_name);
  userData.append("job_title", job_title);
  userData.append("mobile", mobile);
  userData.append("profile_pic", profile_pic);
  userData.append("logo_img", logo_img);

  return new Promise((resolve, reject) => {
    axios
      .post(base_url + "user/register", userData)
      .then((response) => {
        if (response.data.status === "success") {
          NotificationManager.success("User register", "Success");
          resolve(user);
        } else {
          resolve({ message: response.data.msg });
        }
      })
      .catch((error) => error);
  });
};
const signInUserWithEmailPasswordRequest = async (email, password) => {
  const user = { email: email, password: password };
  return new Promise((resolve, reject) => {
    axios
      .get(base_url + "user/login", {
        params: { email: email, password: password },
      })
      .then((response) => {
        if (response.data.status === "success") {
          localStorage.setItem("token", response.data.data.auth_token);
          localStorage.setItem("isFirst", 0);
          localStorage.setItem("user", JSON.stringify(response.data.data));

          let IO_modal = {};
          IO_modal.search_status = "sales";
          IO_modal.quote_search_key = "";
          IO_modal.intelli_products = [];
          IO_modal.sirvez_cnt = 0;
          IO_modal.io_cnt = 0;
          IO_modal.project_id = 0;
          localStorage.setItem("IO_modal", JSON.stringify(IO_modal));

          resolve(user);
        } else {
          resolve({ message: response.data.msg });
        }
      })
      .catch((error) => error);
  });
};
/* 
const signOutRequest = async () =>
    await auth.signOut()
        .then(authUser => authUser)
        .catch(error => error);

 
const signInUserWithGoogleRequest = async () =>
    await auth.signInWithPopup(googleAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

const signInUserWithFacebookRequest = async () =>
    await auth.signInWithPopup(facebookAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

const signInUserWithGithubRequest = async () =>
    await auth.signInWithPopup(githubAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

const signInUserWithTwitterRequest = async () =>
    await auth.signInWithPopup(twitterAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);
 */
function* createUserWithEmailPassword({ payload }) {
  const {
    email,
    password,
    company_name,
    user_name,
    website,
    company_email,
    address,
    city,
    postcode,
    first_name,
    last_name,
    job_title,
    mobile,
    profile_pic,
    logo_img,
  } = payload;
  try {
    const signUpUser = yield call(
      createUserWithEmailPasswordRequest,
      email,
      password,
      company_name,
      user_name,
      website,
      company_email,
      address,
      city,
      postcode,
      first_name,
      last_name,
      job_title,
      mobile,
      profile_pic,
      logo_img
    );
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      // localStorage.setItem('user_id', signUpUser.email);
      yield put(userSignUpSuccess(signUpUser.email));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;

  try {
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password
    );
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else {
      localStorage.setItem("user_id", signInUser.email);
      yield put(userSignInSuccess(signInUser.email));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

/* function* signInUserWithGoogle() {
    try {
        const signUpUser = yield call(signInUserWithGoogleRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userGoogleSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithFacebook() {
    try {
        const signUpUser = yield call(signInUserWithFacebookRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userFacebookSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithGithub() {
    try {
        const signUpUser = yield call(signInUserWithGithubRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userGithubSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithTwitter() {
    try {
        const signUpUser = yield call(signInUserWithTwitterRequest);
        if (signUpUser.message) {
            if (signUpUser.message.length > 100) {
                yield put(showAuthMessage('Your request has been canceled.'));
            } else {
                yield put(showAuthMessage(signUpUser.message));
            }
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userTwitterSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
} 

function* signOut() {
    try {

        const signOutUser = yield call(signOutRequest);

        if (signOutUser === undefined) {
            localStorage.removeItem('user_id');
            yield put(userSignOutSuccess(signOutUser));
        } else {
            yield put(showAuthMessage(signOutUser.message));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}
*/
export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

/* export function* signInWithGoogle() {
    yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
    yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
    yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
    yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
} */

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

/* export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
} */

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    /*   fork(signInWithGoogle),
        fork(signInWithFacebook),
        fork(signInWithTwitter),
        fork(signInWithGithub), */
    //fork(signOutUser)]
  ]);
}
