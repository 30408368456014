import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import asyncComponent from '../../util/asyncComponent';
import Customers from "./Customers";
import TaskManager from "./TaskManager";
import Helpdesk from './Helpdesk';
import Sites from "./Sites";
import project from "./project";
import service from "./service";
import Settings from "./Settings";
import Logout from "./Logout.js";
import Pages from "./Pages";
import FormBuilder from "./FormBuilder";
import Notification from "./Notification";
import Onboarding from "./Onboarding";
import Products from "./Products";

const Routes = ({ match }) =>
        <Switch>
                {/* <Route path={`${match.url}/dashboard`}
                        component={asyncComponent(() => import('./DashBoard'))} /> */}
                <Route path={`${match.url}/accounts`}
                        component={asyncComponent(() => import('./Account'))} />
                <Route path={`${match.url}/notifications`} component={Notification} />
                <Route path={`${match.url}/onboarding`} component={Onboarding} />
                <Route path={`${match.url}/feedbacks`}
                        component={asyncComponent(() => import('./Feedbacks'))} />
                <Route path={`${match.url}/qrcode-scan`} component={asyncComponent(() => import('./DashBoard/QRCodeScan'))} />
                <Route path={`${match.url}/barcode-scan`} component={asyncComponent(() => import('./DashBoard/BarCodeScan'))} />
                <Route path={`${match.url}/customers`} component={Customers} />
                <Route path={`${match.url}/task-manager`} component={TaskManager} />
                <Route path={`${match.url}/helpdesk`} component={Helpdesk} />
                <Route path={`${match.url}/sites`} component={Sites} />
                <Route path={`${match.url}/form`} component={FormBuilder} />
                <Route path={`${match.url}/project`} component={project} />
                <Route path={`${match.url}/service`} component={service} />
                <Route path={`${match.url}/Page`} component={Pages} />
                <Route path={`${match.url}/Products`} component={Products} />
                <Route path={`${match.url}/calendar`}
                        component={asyncComponent(() => import('./Calendar'))} />
                <Route path={`${match.url}/settings`} component={Settings} />
                <Route path={`${match.url}/settings`} component={Settings} />
                <Route path={`${match.url}/logout`} component={Logout} />
                <Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))} />
        </Switch>;

export default withRouter(Routes);

