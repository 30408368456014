import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import asyncComponent from '../../../util/asyncComponent';

const SiteSurvey = ({match}) => (
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/live`}/>
        <Route path={`${match.url}/live`}
                component={asyncComponent(() => import('./routes/live'))}/>
        <Route path={`${match.url}/archived`}
                component={asyncComponent(() => import('./routes/archived'))}/>
        <Route path={`${match.url}/add-new`}
                component={asyncComponent(() => import('./routes/AddNew'))}/>
        <Route path={`${match.url}/sticker`} component={asyncComponent(() => import('./routes/live/EditPhoto.js'))}/>
        <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
);

export default SiteSurvey;
