import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const Helpdesk = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/my-helpdesk`} />
    <Route
      path={`${match.url}/my-helpdesk/:helpdesk_id`}
      component={asyncComponent(() => import("./routes/MyHelpdesk/Main.js"))}
    />
    <Route
      path={`${match.url}/my-helpdesk`}
      component={asyncComponent(() => import("./routes/MyHelpdesk/Main.js"))}
    />
    <Route
      path={`${match.url}/add-new`}
      component={asyncComponent(() => import("./routes/AddNew"))}
    />
    <Route
      component={asyncComponent(() =>
        import("app/routes/extraPages/routes/404")
      )}
    />
  </Switch>
);

export default Helpdesk;
