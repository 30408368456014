import { jsPDF } from "jspdf";
import Moment from "moment"
import { PDFDocument } from "pdf-lib";
import download from "downloadjs";
import { base_url, public_url, $http } from "config";

const StringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

const dynamicSort = (property) => {
  return function (a, b) {
    return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
  };
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function convertFormData(string) {
  string = string.split("-")[0]
  const arr = string.split(" ");

  for (var i = 0; i < arr.length; i++) {
    let _str = arr[i]

    if (_str === "left_of_screen") {
      arr[i] = "Left Of Screen"
    } else if (_str === "right_of_screen") {
      arr[i] = "Right Of Screen"
    } else {
      if (_str.includes("_")) {
        _str = _str.split("_")[0]
      }

      arr[i] = _str.charAt(0).toUpperCase() + _str.slice(1);
    }
  }
  const string2 = arr.join(" ");
  return string2
}

const loadImage = (url) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.addEventListener("load", () => {
      resolve(image);
    });
    image.src = url;
  });
};

function hexToRGB(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null;
}

async function PrintQR(products, qr_option, rooms, com_info) {

  let label_nums = products.length;
  let doc, top, left, h0, w0;
  let doc_width = Number(qr_option.width),
    doc_height = Number(qr_option.height);

  w0 = doc_width;
  h0 = doc_height;

  top = 0.07 * h0;
  left = 0.04 * h0;

  let canvas_width = 0.86 * h0;
  let canvas_height = 0.86 * h0

  // doc = new jsPDF("l", "mm", [doc_width, doc_height], true);

  var img = new Image();
  let arrayBuffers = [];
  for (var item_index = 1; item_index <= label_nums; item_index++) {
    doc = new jsPDF("l", "mm", [doc_width, doc_height], true);
    var data = products[item_index - 1];
    var y0 = 0;
    let canvasUrl = "";
    let canvas;
    if (data?.qr_type == "group_product") {
      canvas = document.querySelector(
        `#group_${data.group_id}_qr_code #react-qrcode-logo`
      );
    } else {
      canvas = document.querySelector(
        `#individual_${data.id}_qr_code #react-qrcode-logo`
      );
    }

    canvasUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    let can_img = new Image();
    can_img.src = canvasUrl;

    doc.addImage(can_img, "JPEG", left, top, canvas_width, canvas_height);

    let x1 = 0.92 * h0;
    let y1 = 0.04 * h0;
    let __width = w0 - 0.94 * h0;

    if (qr_option.company_logo === "1") {
      if (qr_option.qr_logo) {
        const img1 = await loadImage(
          `${public_url}upload/img/${qr_option.qr_logo}`
        );
        // const img1 = await loadImage(
        //   `/test_img_1.jpeg`
        // );

        let img_rate = img1.width / img1.height;
        let can_rate = __width / (0.27 * h0);

        let imh, imw;
        if (can_rate < img_rate) {
          imw = __width;
          imh = imw / img_rate;
          doc.addImage(
            img1,
            "jpeg",
            x1,
            y1 + (0.27 * h0 - imh) / 2,
            imw,
            imh
          );
        } else {
          imh = 0.27 * h0;
          imw = imh * img_rate;
          doc.addImage(
            img1,
            "jpeg",
            0.99 * h0 + (__width - imw) / 2,
            y1,
            imw,
            imh
          );
        }
      }

      y1 = h0 * 0.33;
    }

    let info_rows = [
      { id: "company_name", label: "Company Name" },
      { id: "contact", label: "Contact" },
      { id: "site_name", label: "Site:" },
      { id: "room_name", label: "Location:" },
      { id: "product_name", label: "Product Name" },
      { id: "po_no", label: "Purchase Order" },
      { id: "quote_id", label: "Sales Order" },
      { id: "product_code", label: "Product Code" },
      { id: "warranty_date", label: "Warranty Date" },
    ];

    if (data?.qr_type == "group_product") {
      y1 += h0 * 0.1;

      let __rate = 96 / 25.4;

      let group_font_size = 0.075 * h0 * __rate;
      let group_font_size1 = 0.068 * h0 * __rate;
      doc.setFontSize(group_font_size);
      doc.text("Group:", x1, y1);
      y1 += 0.1 * h0;

      let _string = "";
      if (doc.splitTextToSize(data.group_name, __width).length > 1) {
        _string = doc.splitTextToSize(data.group_name, __width)[0];
        _string = _string.substring(0, _string.length - 2) + "..";
      } else _string = data.group_name;
      doc.setFontSize(group_font_size1);
      doc.text(_string, x1, y1);
      y1 += 0.12 * h0;
      doc.setFontSize(group_font_size);
      doc.text("Qty:", x1, y1);
      y1 += 0.1 * h0;
      doc.setFontSize(group_font_size1);
      doc.text(data.group_count.toString(), x1, y1);
    } else {
      info_rows
        .filter((item) => qr_option[item.id] == "1")
        .map((item) => {
          let title = "";
          let content = "";
          let font_size1;
          let font_size2;
          let __rate = 96 / 25.4;
          font_size1 = 0.05 * h0 * __rate;
          font_size2 = 0.05 * h0 * __rate;
          switch (item.id) {
            case "company_name":
              title = "Installed By:";
              content = com_info.name;
              break;
            case "contact":
              title = `${item.label}: ${data.company_contact}`;
              content = data.company_name;
              break;
            case "warranty_date":
              title = `${item.label}:`;
              let checkin_date = data.checkin_date;
              let warranty_time = data.warranty_time;
              content =
                checkin_date && warranty_time
                  ? Moment(checkin_date)
                    .add(parseInt(warranty_time), "year")
                    .format("MM/DD/YYYY kk:mm:ss")
                  : "";
              break;
            case "product_code":
              title = `Asset Tag: ${public_url}`;
              content = `Product Code: ${data.project_id}/${data.room_id}/${data.id}`;
              break;
            case "site_name":
              title = "Site:";
              content = rooms.find((r) => r.room_id == data.room_id)
                ? rooms.find((r) => r.room_id == data.room_id).site_name
                : "-";
              break;
            case "room_name":
              title = "Location:";
              content = rooms.find((r) => r.room_id == data.room_id)
                ? rooms.find((r) => r.room_id == data.room_id).room_number
                : "-";
              break;
            default:
              title = `${item.label}:`;
              content = data[item.id];
              break;
          }

          let string = "";
          if (doc.splitTextToSize(content, __width).length > 1) {
            string = doc.splitTextToSize(content, __width)[0];
            string = string.substring(0, string.length - 2) + "..";
          } else {
            string = content;
          }

          doc.setFont("times", "normal").setFontSize(font_size1);
          doc.text(title, x1, y1);

          doc.setFont(undefined, "normal").setFontSize(font_size2);
          doc.text(string + "", x1, y1 + 0.082 * h0);

          y1 += 0.16 * h0;
        });
    }

    arrayBuffers.push(doc.output("arraybuffer"));
    if (item_index == label_nums) {
      const mergedPdf = await PDFDocument.create();
      const actions = arrayBuffers.map(async (pdfBuffer) => {
        const pdf = await PDFDocument.load(pdfBuffer);
        const copiedPages = await mergedPdf.copyPages(
          pdf,
          pdf.getPageIndices()
        );
        copiedPages.forEach((page) => {
          mergedPdf.addPage(page);
        });
      });
      await Promise.all(actions);
      const mergedPdfFile = await mergedPdf.save();

      download(mergedPdfFile, `QR_Labels`, "application/pdf");
      return true;
    }
  }
}

export { StringToColor, PrintQR, dynamicSort, getComparator, stableSort, convertFormData, hexToRGB };
