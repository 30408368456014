import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "../../../util/asyncComponent";
// import { prepareAssets } from "./routes/Pdf_setting/utils/prepareAssets";
// prepareAssets();

const Settings = ({ match }) => (
  <Switch>
    {/* <Route path={`${match.url}/company/settings`}
                        component={asyncComponent(() => import('./routes/CompanySettings'))} /> */}
    <Route
      path={`${match.url}/company/settings/:request`}
      component={asyncComponent(() => import("./routes/CompanySettings"))}
    />
    <Route
      path={`${match.url}/company/settings`}
      component={asyncComponent(() => import("./routes/CompanySettings"))}
    />
    <Route
      path={`${match.url}/profile/:user_id`}
      component={asyncComponent(() => import("./routes/Profile"))}
    />
    <Route
      path={`${match.url}/profile`}
      component={asyncComponent(() => import("./routes/Profile"))}
    />

    <Route
      path={`${match.url}/caches`}
      component={asyncComponent(() => import("./routes/Caches"))}
    />
    <Route
      path={`${match.url}/company/label`}
      component={asyncComponent(() => import("./routes/Label"))}
    />
    <Route
      path={`${match.url}/company/pdf`}
      component={asyncComponent(() => import("./routes/Pdf_setting"))}
    />
    <Route
      path={`${match.url}/billing`}
      component={asyncComponent(() => import("./routes/Billing"))}
    />
    <Route
      path={`${match.url}/users`}
      component={asyncComponent(() => import("./routes/Users"))}
    />
    {/* <Route path={`${match.url}/products`} component={Products} /> */}
    <Route path={`${match.url}/stickers`} component={asyncComponent(() => import("./routes/Sticker"))} />
    <Route
      path={`${match.url}/helpdesk`}
      component={asyncComponent(() => import("./routes/Helpdesk"))}
    />
    <Route
      path={`${match.url}/company/partners`}
      component={asyncComponent(() => import("./routes/Partners"))}
    />
    <Route
      path={`${match.url}/proposal`}
      component={asyncComponent(() => import("./routes/Proposal"))}
    />
    <Route
      path={`${match.url}/notifications`}
      component={asyncComponent(() => import("./routes/Notifications"))}
    />
    <Route
      path={`${match.url}/company/project`}
      component={asyncComponent(() => import("./routes/Project"))}
    />
    <Route
      path={`${match.url}/company/qr-option`}
      component={asyncComponent(() => import("./routes/qr-option"))}
    />
    <Route
      path={`${match.url}/company/barcode_api`}
      component={asyncComponent(() => import("./routes/barcode-api"))}
    />
    <Route
      component={asyncComponent(() =>
        import("app/routes/extraPages/routes/404")
      )}
    />
  </Switch>
);

export default Settings;
