import axios from "axios";
import { LogLevel } from "@azure/msal-browser";

// const base_url = "https://bluvisual.com/api/";
// const public_url = "https://bluvisual.com/";
// const sticker_url = "https://bluvisual.com/pixie/assets/images/stickers/";

const base_url = "https://app.sirvez.com/api/";
const public_url = "https://app.sirvez.com/";
const sticker_url = "https://app.sirvez.com/pixie/assets/images/stickers/";

const $http = axios.create({
  baseURL: base_url,
  timeout: 100000,
  // headers: {
  //   "Content-Type": "application/json; multipart/form-data"
  // },
});

$http.interceptors.request.use((request) => {
  if (localStorage.getItem("token"))
    request.headers = {
      "Content-Type": "application/json; multipart/form-data",
      "X-Auth-Token": localStorage.getItem("token"),
    };
  request.mode = "cors";
  return request;
});

$http.interceptors.response.use(
  function(response) {
    if (response.data.status === "TokenError") {
      //perform the manipulation here and change the response object
      localStorage.removeItem("user_id");
      alert(response.data.msg);
      return;
    }
    return response;
  },
  function(error) {
    if (!navigator.onLine)
      return Promise.reject(
        "You are offline. Your request will send when online."
      );
    else return Promise.reject(error.message);
  }
);

const config = {
  base_url: base_url,
};

const FROM_NETWORK = 1;
const FROM_CACHE = 2;
const CACHE_NAME = "SirvezApp";

const getResponsePWA = (
  fetch_url,
  updateNetworkResponse,
  failedNetworkResponse = undefined
) => {
  $http
    .get(fetch_url)
    .then((response) => {
      updateNetworkResponse(response.data);
    })
    .catch((error) => {
      if (typeof failedNetworkResponse === "function") {
        failedNetworkResponse(error);
      }
    });

  if (window.location.hostname === "10.10.13.231") return;
  caches.open(CACHE_NAME).then(function(cache) {
    cache
      .match(fetch_url)
      .then(function(response) {
        return response ? response.json() : null;
      })
      .then(function(data) {
        if (!data) return;
        // console.log('from cache', fetch_url, data);
        updateNetworkResponse(data, FROM_CACHE);
      });
  });
};

const GBP_CURRENCY = (data) =>
  Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }).format(data);

const CurrencyShow = (data, name) => {
  switch (name) {
    case "GBP":
      return Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
      }).format(data);

    case "USD":
      return Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(data);

    default:
      break;
  }
};

const getResponseFromServer = (
  fetch_url,
  updateNetworkResponse,
  failedNetworkResponse = undefined
) => {
  $http
    .get(fetch_url)
    .then((response) => {
      updateNetworkResponse(response.data, FROM_NETWORK);
    })
    .catch((error) => {
      if (typeof failedNetworkResponse === "function") {
        failedNetworkResponse(error);
      }
    });
};

const AUTHUSER = JSON.parse(localStorage.getItem("user"));

const sendNotification = (
  title,
  text,
  image,
  notification_id,
  action_link,
  background
) => {
  Notification.requestPermission(function(result) {
    if (result == "granted") {
      const options = {
        body: text,
        icon: `${public_url}upload/img/${image}`,
        vibrate: [300, 100, 300],
        tag: notification_id,
        image: `${public_url}upload/img/${background}`,
        badge: "/logo192.png",
        renotify: true,
        data: { url: action_link },
        actions: [
          { action: "sirvez-note", title: "view", icon: "/logo192.png" },
        ],
      };
      navigator.serviceWorker.ready.then(function(serviceWorker) {
        serviceWorker.showNotification(title, options);
      });
    }
  });
};

const pusher = {
  key: "ded1167b2fd4d20a06f6",
  cluster: "eu",
};

// const CALENDAR_ID='im9a4p3f0oe88f6d1fjotikav0@group.calendar.google.com'
const CALENDAR_ID = "primary";

const msalConfig = {
  auth: {
    clientId: "ec85aa65-aca9-4233-8602-3fa6453b3ab0",
    authority: "https://login.microsoftonline.com/common",
    redirectUri: `${public_url}app/settings/profile`,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

const loginRequest = {
  appId: "ec85aa65-aca9-4233-8602-3fa6453b3ab0",
  redirectUri: `${public_url}app/settings/profile`,
  scopes: [
    "User.Read",
    "mailboxsettings.read",
    "calendars.readwrite",
    "Mail.Read",
    "Mail.Send",
  ],
};

const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

const graphConfig = {
  graphMeEndpoint: `https://graph.microsoft.com/v1.0/me`,
  EventEndPoint: `https://graph.microsoft.com/v1.0/me/events`,
  MailEndPoint: `https://graph.microsoft.com/v1.0/me/messages`,
  SendMailEndPoint: `https://graph.microsoft.com/v1.0/me/sendMail`,
};

const CreateEvent = async (accessToken, event) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(event),
  };
  return fetch(graphConfig.EventEndPoint, options)
    .then((response) => response.json())
    .catch((err) => console.log("event err", err));
};

const UpdateEvent = async (accessToken, event, event_id) => {
  const options = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(event),
  };
  return fetch(`${graphConfig.EventEndPoint}/${event_id}`, options)
    .then((response) => response.json())
    .catch((err) => console.log("event update err", err));
};

const RemoveEvent = async (accessToken, event_id) => {
  const options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
  };
  return fetch(`${graphConfig.EventEndPoint}/${event_id}`, options);
};

const SendMail = (accessToken, userId, sendMail) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(sendMail),
  };
  fetch(
    `https://graph.microsoft.com/v1.0/users/${userId}/sendMail`,
    options
  ).then((response) => {
    if (response.status >= 300) {
      fetch(graphConfig.SendMailEndPoint, options);
    }
  });
};

const ICECAT_ACCESS_TOKEN = "ae203c55-bd3a-47d8-a026-9937800808e5";

const gapiConfig = {
  clientId:
    "274900488487-thqem0in6cj177huar2gen87gaisntio.apps.googleusercontent.com",
  apiKey: "AIzaSyByvrhB4luTNsDN3th9PdhEqErX_vsAy8U",
  scope:
    "https://www.googleapis.com/auth/contacts https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/gmail.modify",
  discoervyDocs: [
    "https://people.googleapis.com/$discovery/rest?version=v1",
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    "https://gmail.googleapis.com/$discovery/rest?version=v1",
  ],
};

const algoliaSearch = {
  app_name: "7ZQCGLKONV",
  access_token: "99536b5c9bac6123c72d55512a97c349",
};

const SearchIO = {
  account_id: "1648148677034494387",
  collection_id: "sirvez-products4",
  project_collect_id: "sirvez-project2",
};

export default config;
export {
  base_url,
  public_url,
  pusher,
  sticker_url,
  $http,
  CALENDAR_ID,
  msalConfig,
  ICECAT_ACCESS_TOKEN,
  loginRequest,
  CreateEvent,
  UpdateEvent,
  RemoveEvent,
  SendMail,
  sendNotification,
  gapiConfig,
  algoliaSearch,
  getResponsePWA,
  getResponseFromServer,
  SearchIO,
  stringToColor,
  GBP_CURRENCY,
  CurrencyShow,
  AUTHUSER,
};
