import React from "react";
import { List } from "@material-ui/core";
import { NavLink } from "react-router-dom";

import IntlMessages from "../../util/IntlMessages";

const NavMenuItem = (props) => {
  const { name, icon, link, count, count2, sign_cnt } = props;
  return (
    <List component="div" className="nav-menu-item" disablePadding>
      <NavLink className="prepend-icon nav-menu-link" to={link}>
        <div className="inline-middle justify-content-between">
          {/* Display an icon if any */}
          <div className="inline-middle">
            {!!icon && <i className={`sir sir-icon-${icon} font-size-20`} />}
            <span className="nav-text">
              <IntlMessages id={name} />
            </span>
          </div>

          <div className="chat-date inline-middle">
            {sign_cnt ? (
              <div className="bg-red rounded-circle badge text-white m-0 mr-1">
                {sign_cnt}
              </div>
            ) : null}
            <div className="bg-teal rounded-circle badge text-white m-0">
              {icon === "task" ? `${count} | ${count2}` : count}
            </div>
          </div>
        </div>
      </NavLink>
    </List>
  );
};

export default NavMenuItem;
