import React, { useState, useEffect } from "react";
import ContainerHeader from "components/ContainerHeader";
import IntlMessages from "util/IntlMessages";
import CardBox from "components/CardBox";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import { Card, CardActions, CardContent } from "@material-ui/core";

import PersistentNotification from "./PersistentNotification";
import { base_url, getResponsePWA, $http } from "config";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IntegrationReactMultiSelect from "components/autoComplete/reactSelect/IntegrationReactMultiSelect";
import IntegrationReactOutlineMultiSelect from "components/autoComplete/reactSelect/IntegrationReactOutlineMultiSelect";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Modal, ModalHeader } from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import { useDropzone } from "react-dropzone";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import NotificationCell from "components/NotificationPage";
import Radio from "@material-ui/core/Radio";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  DateTimePicker,
  DatePicker,
} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import { scale } from "pdf-lib";
import { withStyles } from "@material-ui/styles";

const Notification = (props) => {
  const [new_noti_modal, setNewNotiModal] = useState(false);

  const [notifications, setNotifications] = useState([]);
  const [notification_pages, setNotificationPages] = useState([]);
  const [all_noti_pages, setAllNotiPages] = useState([]);
  const [loader, setLoader] = useState(false);
  const [goback, setGoback] = useState(false);
  const [companies, setCompanies] = useState([]);

  const [timeline_setting, setTimelineSetting] = useState(false);
  const [add_new_modal, setAddNewModal] = useState(false);
  const [show_livepreview, setShowLivepreview] = useState(true);

  const nowuser = JSON.parse(localStorage.getItem("user"));

  const [new_noti, setNewNoti] = useState({
    id: 0,
    name: "",
    status: 1,
    include_pages: [],
    notification_title: "",
    main_title: "",
    only_show_companies: [],
    exclude_companies: [],
    display_from: null,
    display_to: null,
    upload_video_url: "",
    video_url: "",
    button_text: "",
    button_url: "",
    background_color: "#f44336",
    customer_type: "1",
  });

  const status_rows = [
    { id: 1, content: "Active" },
    { id: 0, content: "Inactive" },
  ];

  const color_theme = [
    { id: 0, color: "#f44336", name: "Red" },
    { id: 1, color: "#e91e36", name: "Pink" },
    { id: 2, color: "#9c27b0", name: "Purple" },
    { id: 3, color: "#00e676", name: "Green" },
    { id: 4, color: "#2979ff", name: "Blue" },
    { id: 5, color: "#b28704", name: "Yellow" },
    { id: 6, color: "#ff9800", name: "Orange" },
    { id: 7, color: "#c6ff00", name: "Lime" },
  ];

  const CloseNotiModal = () => {
    setNewNotiModal(false);
  };

  useEffect(() => {
    initData();
  }, []);

  const removeNoti = (id) => {
    const temp_noties = notifications.filter((item) => item.id != id);
    setNotifications(temp_noties);
  };

  const initNewNoti = () => {
    setNewNoti({
      ...new_noti,
      id: 0,
      name: "",
      status: 1,
      include_pages: [],
      notification_title: "",
      main_title: "",
      only_show_companies: [],
      exclude_companies: [],
      display_from: null,
      display_to: null,
      upload_video_url: "",
      video_url: "",
      button_text: "",
      button_url: "",
      background_color: "#f44336",
      customer_type: "1",
    });
  };

  const initData = () => {
    getResponsePWA(
      `${base_url}notification/notificationList`,
      updatedNotifications,
      failedNotifications
    );
  };

  const updatedNotifications = (data) => {
    setNotifications(data.notifications);
    let noti_pages = data.notification_pages;
    noti_pages.unshift({
      id: 0,
      page_name: "All",
      is_enduser: 1,
    });
    setNotificationPages(noti_pages);
    setAllNotiPages(noti_pages);
    setCompanies(data.companies);
    setLoader(true);
  };

  const failedNotifications = (data) => {
    setGoback(true);
  };

  const addNewNotification = () => {
    const frmData = new FormData();

    frmData.append("new_noti", JSON.stringify(new_noti));
    if (new_noti["display_from"])
      frmData.append(
        "start_time",
        moment(new_noti["display_from"]).format("MMM Do, YYYY")
      );
    if (new_noti["display_to"])
      frmData.append(
        "end_time",
        moment(new_noti["display_to"]).format("MMM Do, YYYY")
      );

    setNewNotiModal(false);
    $http
      .post(`${base_url}notification/addNewNotification`, frmData)
      .then((response) => {
        if (response.data.status === "success") {
          NotificationManager.success(
            "Added new notification successfully",
            "Success!",
            1500,
            () => {}
          );
          initNewNoti();
          initData();
        } else if (response.data.status === "error") {
          NotificationManager.error(
            response.data.msg,
            "Error!",
            1000,
            () => {}
          );
        }
      })
      .catch((error) => {
        NotificationManager.error(error, "Error!", 1000, () => {});
      });
  };

  const handleClickNoti = (data) => {
    setNewNotiModal(true);
    setAddNewModal(false);
    setNewNoti({
      ...new_noti,
      id: data.id,
      name: data.name,
      status: data.status == "Active" ? 1 : 0,
      include_pages: data.assign_pages,
      notification_title: data.notification_title,
      main_title: data.main_title,
      only_show_companies: [],
      exclude_companies: [],
      display_from: data.display_from ? new Date(data.display_from) : null,
      display_to: data.display_to ? new Date(data.display_to) : null,
      upload_video_url: data.video_url,
      video_url: "",
      button_text: "",
      button_url: "",
      background_color: data.background_color,
      customer_type: data.onboarding.toString(),
    });
    setTimelineSetting(data.display_from || data.display_to ? true : false);
  };

  const NewNotiName = () => {
    return (
      <Paper variant="outlined" className="p-3 mb-3">
        <h2 className="mb-0">
          <strong>Name</strong>
        </h2>
        <div className="row align-items-center">
          <div className="col-3 text-right">
            <h5 className="mb-0">Name</h5>
          </div>
          <div className="col-9">
            <TextField
              id="name"
              variant="outlined"
              required
              value={new_noti.name}
              onChange={(event) =>
                setNewNoti({ ...new_noti, name: event.target.value })
              }
              margin="dense"
              fullWidth
            />
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-3 text-right">
            <h5 className="mb-0">Type</h5>
          </div>
          <div className="col-9">
            <FormControl component="fieldset" required>
              <RadioGroup
                className="d-flex flex-row"
                aria-label="Customer Type"
                name="Customer Type"
                value={new_noti["customer_type"]}
                onChange={(event) => {
                  setNewNoti({
                    ...new_noti,
                    customer_type: event.target.value,
                  });
                  if (event.target.value == "2")
                    setNotificationPages([
                      ...all_noti_pages.filter((item) => item.is_enduser == 1),
                    ]);
                  else setNotificationPages([...all_noti_pages]);
                }}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio className="px-1" color="primary" />}
                  label="Onboarding"
                />
                <FormControlLabel
                  value="0"
                  control={<Radio className="px-1" color="primary" />}
                  label="Regular"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio className="px-1" color="primary" />}
                  label="End User"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-3 text-right">
            <h5 className="mb-0">Status</h5>
          </div>
          <div className="col-9">
            <TextField
              id="in_active"
              select
              variant="outlined"
              value={new_noti.status}
              onChange={(event) =>
                setNewNoti({ ...new_noti, status: event.target.value })
              }
              margin="dense"
              fullWidth
            >
              {status_rows.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.content}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
      </Paper>
    );
  };

  const NewNotiDisplay = () => {
    return (
      <Paper variant="outlined" className="p-3">
        <h2 className="mb-0">
          <strong>Display</strong>
        </h2>
        <div className="row align-items-center mt-3">
          <div className="col-3 text-right">
            <h5 className="mb-0">Notification Pages</h5>
          </div>
          <div className="col-9">
            <IntegrationReactOutlineMultiSelect
              suggestions={notification_pages.map((item) => {
                return { value: item.id, label: item.page_name };
              })}
              value={
                new_noti["include_pages"].length != 8
                  ? notification_pages
                      .filter(
                        (item) =>
                          new_noti["include_pages"].indexOf(item.id) != -1
                      )
                      .map((item) => {
                        return { value: item.id, label: item.page_name };
                      })
                  : notification_pages
                      .filter((item) => item.id == 0)
                      .map((item) => {
                        return { value: item.id, label: item.page_name };
                      })
              }
              onSelect={(val) =>
                setNewNoti({ ...new_noti, include_pages: val })
              }
            />
          </div>
        </div>
        <div className="row align-items-center my-2">
          <div className="col-3 text-right">
            <h5 className="mb-0">Dates(From/till)</h5>
          </div>
          <div className="col-9 d-flex">
            <Checkbox
              color="secondary"
              checked={timeline_setting}
              onChange={(event) => {
                setTimelineSetting(event.target.checked);
                if (event.target.checked)
                  setNewNoti({
                    ...new_noti,
                    display_from: new Date(),
                    end_datetime: new Date(Date.now() + 86400000),
                    display_to: new Date(Date.now() + 86400000),
                  });
                else
                  setNewNoti({
                    ...new_noti,
                    display_from: null,
                    display_to: null,
                  });
              }}
              className="px-0 mr-1"
            />
            {timeline_setting && (
              <div className="d-flex">
                <div className="mr-2">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      fullWidth
                      autoOk
                      inputVariant="outlined"
                      showTabs={false}
                      value={new_noti["display_from"]}
                      onChange={(date) =>
                        setNewNoti({ ...new_noti, display_from: date })
                      }
                      label="From"
                      margin="dense"
                      format="dd MMM, yy"
                      minutesStep={10}
                      leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                      rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <i className="zmdi zmdi-alarm" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className="">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      fullWidth
                      autoOk
                      inputVariant="outlined"
                      showTabs={false}
                      value={new_noti["display_to"]}
                      onChange={(date) =>
                        setNewNoti({ ...new_noti, display_to: date })
                      }
                      label="To"
                      margin="dense"
                      format="dd MMM, yy"
                      minutesStep={10}
                      leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                      rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <i className="zmdi zmdi-alarm" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row align-items-center mb-3">
          <div className="col-3 text-right">
            <h5 className="mb-0">Exclude Customers</h5>
          </div>
          <div className="col-9 d-flex">
            <IntegrationReactOutlineMultiSelect
              suggestions={companies.map((item) => {
                return { value: item.id, label: item.name };
              })}
              margin="dense"
              value={companies
                .filter(
                  (item) => new_noti["exclude_companies"].indexOf(item.id) != -1
                )
                .map((item) => {
                  return { value: item.id, label: item.name };
                })}
              onSelect={(val) =>
                setNewNoti({ ...new_noti, exclude_companies: val })
              }
            />
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-3 text-right">
            <h5 className="mb-0">Only Show to customers</h5>
          </div>
          <div className="col-9 d-flex">
            <IntegrationReactOutlineMultiSelect
              suggestions={companies.map((item) => {
                return { value: item.id, label: item.name };
              })}
              value={companies
                .filter(
                  (item) =>
                    new_noti["only_show_companies"].indexOf(item.id) != -1
                )
                .map((item) => {
                  return { value: item.id, label: item.name };
                })}
              onSelect={(val) =>
                setNewNoti({ ...new_noti, only_show_companies: val })
              }
            />
          </div>
        </div>
      </Paper>
    );
  };

  const NewNotiContent = () => {
    return (
      <Paper variant="outlined" className="p-3 mb-3">
        <h2 className="mb-0">
          <strong>Content</strong>
        </h2>
        <div className="row align-items-center">
          <div className="col-3 text-right">
            <h5 className="mb-0">Title</h5>
          </div>
          <div className="col-9">
            <TextField
              id="title"
              variant="outlined"
              required
              value={new_noti.notification_title}
              onChange={(event) =>
                setNewNoti({
                  ...new_noti,
                  notification_title: event.target.value,
                })
              }
              margin="dense"
              fullWidth
            />
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-3 text-right">
            <h5 className="mb-0">Body</h5>
          </div>
          <div className="col-9">
            <TextField
              id="body"
              variant="outlined"
              required
              value={new_noti.main_title}
              onChange={(event) =>
                setNewNoti({ ...new_noti, main_title: event.target.value })
              }
              margin="dense"
              fullWidth
            />
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-3 text-right">
            <h5 className="mb-0">Add Video</h5>
          </div>
          <div className="col-9">
            <Checkbox
              color="secondary"
              onClick={(event) => {
                if (event.target.checked)
                  setNewNoti({ ...new_noti, video_url: event.target.checked });
                else setNewNoti({ ...new_noti, video_url: "" });
              }}
            />
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-3 text-right">
            <h5 className="mb-0">Video URL</h5>
          </div>
          <div className="col-9">
            <TextField
              id="video_url"
              variant="outlined"
              required
              value={new_noti["upload_video_url"]}
              onChange={(event) =>
                setNewNoti({
                  ...new_noti,
                  upload_video_url: event.target.value,
                })
              }
              margin="dense"
              fullWidth
            />
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-3 text-right">
            <h5 className="mb-0">Add Button</h5>
          </div>
          <div className="col-9">
            <Checkbox
              color="secondary"
              onClick={(event) => {
                if (event.target.checked) console.log("button checked");
                else console.log("not checked");
              }}
            />
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-3 text-right">
            <h5 className="mb-0">Button Text</h5>
          </div>
          <div className="col-9">
            <TextField
              id="button_text"
              variant="outlined"
              required
              value={new_noti["button_text"]}
              onChange={(event) =>
                setNewNoti({ ...new_noti, button_text: event.target.value })
              }
              margin="dense"
              fullWidth
            />
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-3 text-right">
            <h5 className="mb-0">Button URL</h5>
          </div>
          <div className="col-9">
            <TextField
              id="button_url"
              variant="outlined"
              required
              value={new_noti["button_url"]}
              onChange={(event) =>
                setNewNoti({ ...new_noti, button_url: event.target.value })
              }
              margin="dense"
              fullWidth
            />
          </div>
        </div>
      </Paper>
    );
  };

  const NewNotiStyle = () => {
    return (
      <Paper variant="outlined" className="p-3 mb-3">
        <h2 className="mb-0">
          <strong>Style</strong>
        </h2>
        <div className="row align-items-center">
          <div className="col-3 text-right">
            <h5 className="mb-0">Colour</h5>
          </div>
          <div className="col-9">
            <TextField
              id="background_color"
              select
              variant="outlined"
              required
              value={new_noti["background_color"]}
              onChange={(event) =>
                setNewNoti({
                  ...new_noti,
                  background_color: event.target.value,
                })
              }
              margin="dense"
              fullWidth
            >
              {color_theme.map((item) => (
                <MenuItem key={item.id} value={item.color}>
                  <div className="inline-middle">
                    <div
                      className="mr-3"
                      style={{
                        width: 50,
                        height: 25,
                        background: `${item.color}`,
                      }}
                    ></div>
                    <span>{item.name}</span>
                  </div>
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
      </Paper>
    );
  };

  return (
    <div className="app-wrapper">
      <ContainerHeader
        match={props.match}
        title={<IntlMessages id="sidebar.notification" />}
      />
      <div className="text-right">
        <Tooltip title="New Notification">
          <IconButton
            aria-label="New Notification"
            onClick={() => {
              setNewNotiModal(true);
              setAddNewModal(true);
            }}
          >
            <i className="zmdi zmdi-plus-circle text-primary"></i>
          </IconButton>
        </Tooltip>
      </div>
      <div className="row">
        <CardBox styleName="col-12" cardStyle="p-0">
          <PersistentNotification
            notifications={notifications}
            notificationPages={notification_pages}
            loader={loader}
            goback={goback}
            handleClickNoti={handleClickNoti}
            initData={initData}
            removeNoti={removeNoti}
          />
        </CardBox>
      </div>

      <Modal
        key="customer-save"
        className="comment_modal modal-box"
        toggle={CloseNotiModal}
        isOpen={new_noti_modal}
      >
        <ModalHeader
          className={`text-white ${
            add_new_modal ? "bg-primary" : "bg-secondary"
          }`}
        >
          {`${add_new_modal ? "Add New Notification" : "Modify Notification"}`}
          <IconButton className="text-grey py-0" onClick={CloseNotiModal}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>
        <div className="modal-box-content row">
          <div className="col-12">
            <Card>
              <CardContent>
                <div className="row">
                  <div className="col-md-6 col-12">
                    {NewNotiName()}
                    {NewNotiDisplay()}
                  </div>
                  <div className="col-md-6 col-12">
                    {NewNotiContent()}
                    {NewNotiStyle()}
                  </div>
                </div>

                {show_livepreview && (
                  <div className="row flex-column">
                    <h3 className="col-12">Live Preview</h3>
                    <div className="col-12">
                      <NotificationCell
                        data={new_noti}
                        handleClickNoti={() => setShowLivepreview(false)}
                      />
                    </div>
                  </div>
                )}
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color={`${add_new_modal ? "primary" : "secondary"}`}
                  onClick={() => addNewNotification()}
                  className="ml-auto mr-2 mb-2 px-5"
                >
                  Save
                </Button>
              </CardActions>
            </Card>
          </div>
        </div>
      </Modal>

      <NotificationContainer />
    </div>
  );
};

export default Notification;
