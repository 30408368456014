import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useHistory, useParams } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import axios from "axios";
import { base_url, $http } from "config";

import { userForgotSuccess } from "actions/Auth";

const Forgot = (props) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [re_password, setRePassword] = useState("");
  const [not_match, setNotMatch] = useState(false);

  const History = useHistory();
  const params = useParams();

  useEffect(() => {
    if (params.token && params.token != "") handleGetEmail();
  }, []);

  const handleGetEmail = () => {
    $http
      .get(`${base_url}user/getEmailFromToken`, {
        params: { token: params.token },
      })
      .then((response) => {
        if (response.data.status == "success") setEmail(response.data.email);
      })
      .catch((error) => {
        console.log("activities", error);
        this.setState({ goback: true });
      });
  };

  const SendForgotEmail = () => {
    if (!email) {
      NotificationManager.error(
        "You have to input email in the field!",
        "error!",
        1000,
        () => {}
      );
      return;
    }

    if (params.token && password == "" && password !== re_password) {
      setNotMatch(true);
      return;
    }

    const frmData = new FormData();
    frmData.append("email", email);
    frmData.append("password", password);
    frmData.append("token", params.token);
    $http
      .post(base_url + "user/forgot", frmData)
      .then((response) => {
        if (response.data.status === "success") {
          if (params.token && password != "") {
            dispatch(userForgotSuccess());
            History.push({ pathname: "/signin" });
          } else
            NotificationManager.success(
              "Success send email! Please check your email!",
              "success!",
              1000,
              () => {}
            );
        } else {
          if (response.data.msg)
            NotificationManager.error(
              response.data.msg,
              "error!",
              2000,
              () => {}
            );
          else
            NotificationManager.error(
              "The email does not exist in the database!",
              "error!",
              2000,
              () => {}
            );
        }
      })
      .catch((error) => error);
  };
  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="login-content">
        <div className="login-header">
          <Link className="app-logo" to="/" title="Jambo">
            <img
              src={require("assets/images/logo_dark.png")}
              alt="jambo"
              title="jambo"
            />
          </Link>
        </div>

        <div className="mb-4">
          <h2>
            <IntlMessages id="appModule.forgotPassword" />
          </h2>
        </div>

        <div className="login-form">
          <form method="post" action="/">
            <div className="form-group mb-3">
              <TextField
                type="email"
                id="required"
                label={<IntlMessages id="appModule.email" />}
                fullWidth
                variant="outlined"
                defaultValue=""
                disabled={params.token ? true : false}
                margin="normal"
                className="mt-0 mb-4"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </div>

            {params.token ? (
              <>
                <div className="form-group mb-3">
                  <TextField
                    type="password"
                    id="required"
                    label="password"
                    fullWidth
                    variant="outlined"
                    defaultValue=""
                    margin="normal"
                    className="mt-0 mb-4"
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />
                </div>

                <div className="form-group mb-3">
                  <TextField
                    type="password"
                    id="required"
                    label="Re-type password"
                    fullWidth
                    variant="outlined"
                    defaultValue=""
                    margin="normal"
                    error={not_match}
                    helperText={not_match ? "Password doesn't match" : ""}
                    className="mt-0 mb-4"
                    value={re_password}
                    onChange={(event) => {
                      setRePassword(event.target.value);
                    }}
                  />
                </div>
              </>
            ) : null}

            <div className="text-center">
              <Button
                className="btn btn-primary jr-btn-rounded bg-primary text-white"
                variant="contained"
                onClick={() => SendForgotEmail()}
              >
                <IntlMessages id="appModule.resetPassword" />
              </Button>
            </div>
          </form>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default Forgot;
