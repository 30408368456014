import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Customers = ({match}) => (
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/company/`}/>
      <Route path={`${match.url}/company`} component={asyncComponent(() => import('./routes/Company'))}/>
      <Route path={`${match.url}/floorPlan`} component={asyncComponent(() => import('./routes/FloorPlan'))}/>
      <Route path={`${match.url}/users`} component={asyncComponent(() => import('./routes/Users'))}/>
      <Route path={`${match.url}/pending-invites`} component={asyncComponent(() => import('./routes/PendingInvites'))}/>
      <Route exact from={`${match.url}/customer-edit/`} component={asyncComponent(() => import('./routes/Company/CustomerModify.js'))}/>
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
);

export default Customers;