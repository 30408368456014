import React, { useState, useEffect } from "react";
import { public_url } from "config";
import Button from "@material-ui/core/Button";
import { Helmet } from "react-helmet";
import { $http } from "config";

const LeftSidebarForEnduser = (props) => {
  const { customer } = props;
  //const {onlineUsers} = useSelector(({settings}) => settings);
  const [livechat_open, setLivechatOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (livechat_open)
      document.getElementById("__id").style.visibility = "visible";
    return () => {};
  }, []);
  return (
    <div>
      {customer && (
        <div className="m-4 bg-black rounded p-3">
          <div className="inline-middle m-2">
            {`You are logged into the ${customer.integrator} customer Portal for ${customer.name}`}
          </div>
          {customer.logo_img && (
            <div>
              <img
                src={`${public_url}upload/img/${customer.logo_img}`}
                alt={customer.name}
                className="w-100 h-100"
              />
            </div>
          )}
        </div>
      )}
      {livechat_open && (
        <Helmet>
          <script>
            {`!(function(e, t) {
        if (!e.groove) {
          var i = function(e, t) {
              return Array.prototype.slice.call(e, t);
            },
            a = {
              widget: null,
              loadedWidgets: {},
              classes: {
                Shim: null,
                Embeddable: function() {
                  (this._beforeLoadCallQueue = []),
                    (this.shim = null),
                    (this.finalized = !1);
                  var e = function(e) {
                    var t = i(arguments, 1);
                    if (this.finalized) {
                      if (!this[e])
                        throw new TypeError(
                          e + "() is not a valid widget method"
                        );
                      this[e].apply(this, t);
                    } else this._beforeLoadCallQueue.push([e, t]);
                  };
                  (this.initializeShim = function() {
                    a.classes.Shim && (this.shim = new a.classes.Shim(this));
                  }),
                    (this.exec = e),
                    (this.init = function() {
                      e.apply(this, ["init"].concat(i(arguments, 0))),
                        this.initializeShim();
                    }),
                    (this.onShimScriptLoad = this.initializeShim.bind(this)),
                    (this.onload = void 0);
                },
              },
              scriptLoader: {
                callbacks: {},
                states: {},
                load: function(e, i) {
                  if ("pending" !== this.states[e]) {
                    this.states[e] = "pending";
                    var a = t.createElement("script");
                    (a.id = e),
                      (a.type = "text/javascript"),
                      (a.async = !0),
                      (a.src = i);
                    var s = this;
                    a.addEventListener(
                      "load",
                      function() {
                        (s.states[e] = "completed"),
                          (s.callbacks[e] || []).forEach(function(e) {
                            e();
                          });
                      },
                      !1
                    );
                    var n = t.getElementsByTagName("script")[0];
                    n.parentNode.insertBefore(a, n);
                  }
                },
                addListener: function(e, t) {
                  "completed" !== this.states[e]
                    ? (this.callbacks[e] || (this.callbacks[e] = []),
                      this.callbacks[e].push(t))
                    : t();
                },
              },
              createEmbeddable: function() {
                var t = new a.classes.Embeddable();
                return e.Proxy
                  ? new Proxy(t, {
                      get: function(e, t) {
                        return e instanceof a.classes.Embeddable
                          ? Object.prototype.hasOwnProperty.call(e, t) ||
                            "onload" === t
                            ? e[t]
                            : function() {
                                e.exec.apply(e, [t].concat(i(arguments, 0)));
                              }
                          : e[t];
                      },
                    })
                  : t;
              },
              createWidget: function() {
                var e = a.createEmbeddable();
                return (
                  a.scriptLoader.load(
                    "groove-script",
                    "https://e7404802-e426-41d2-a0e6-4a31da588d18.widget.cluster.groovehq.com/api/loader"
                  ),
                  a.scriptLoader.addListener(
                    "groove-iframe-shim-loader",
                    e.onShimScriptLoad
                  ),
                  e
                );
              },
            };
          e.groove = a;
        }
      })(window, document);
      window.groove.widget = window.groove.createWidget();
      window.groove.widget.init("e7404802-e426-41d2-a0e6-4a31da588d18", {});`}
          </script>
        </Helmet>
      )}
      <div className="bg-black rounded p-2 m-4 text-center">
        <Button
          startIcon={<img src="/chat.png" />}
          size="large"
          onClick={() => setLivechatOpen(true)}
          className="text-white text-capitalize"
          style={{ fontSize: 20 }}
        >
          Live Chat
        </Button>
      </div>
    </div>
  );
};
export default LeftSidebarForEnduser;
