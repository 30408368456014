import React, { useEffect, useState } from "react";
import CustomScrollbars from "util/CustomScrollbars";
import Navigation from "../../components/Navigation";
import OnlineUsers from "./OnlineUsers";
import LeftSidebarForEnduser from "./LeftSidebarForEnduser";
import { base_url, $http, getResponsePWA } from "config";

const SideBarContent = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [project_count, setProjectCount] = useState(0);
  const [signoff_count, setSignoffCount] = useState(0);
  const [task_count, setTaskCount] = useState(0);
  const [subtask_count, setSubtaskCount] = useState(0);
  const [helpdesk_count, setHelpdeskCount] = useState(0);
  const [navigationMenus, setNavigationMenus] = useState([]);
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    getResponsePWA(
      `${base_url}user/getProjectTaskCount`,
      updatedProjectTask,
      failedProjectTask
    );
    updateNavigationMenus();
  }, []);

  const updatedProjectTask = (data) => {
    setProjectCount(data.project_count);
    setSignoffCount(data.signoff_count);
    setTaskCount(data.task_count);
    setSubtaskCount(data.subtask_count);
    setHelpdeskCount(data.Helpdesk_count);
    updateNavigationMenus(
      data.project_count,
      data.signoff_count,
      data.task_count,
      data.subtask_count,
      data.Helpdesk_count
    );
    setCustomer(data.customer);
  };

  const failedProjectTask = (data) => {
    console.log("**", data);
  };

  const updateNavigationMenus = (
    project_cnt = project_count,
    signoff_cnt = signoff_count,
    task_cnt = task_count,
    subtask_cnt = subtask_count,
    helpdesk_cnt = helpdesk_count
  ) => {
    if (user.user_type == 0) {
      // super super admin
      setNavigationMenus([
        /*   {
            name: "sidebar.dashboard",
            icon: "dashboard",
            type: "item",
            link: "/app/dashboard",
          }, */
        {
          name: "sidebar.account",
          icon: "assignment-account",
          type: "item",
          link: "/app/accounts",
        },
        {
          name: "sidebar.notification",
          icon: "notifications-active",
          type: "item",
          link: "/app/notifications",
        },
        {
          name: "sidebar.customers.projects",
          icon: "projects",
          type: "item",
          link: "/app/project/live",
          count: project_cnt,
        },
        {
          name: "sidebar.taskmanager.workflows",
          icon: "workflow",
          type: "collapse",
          children: [
            {
              name: "sidebar.taskmanager.addnew",
              icon: "new",
              type: "item",
              link: "/app/task-manager/add-new",
            },
            {
              name: "sidebar.taskmanager.mytasks",
              icon: "task",
              type: "item",
              count: task_cnt,
              count2: subtask_cnt,
              link: "/app/task-manager/my-tasks",
            },
            {
              name: "sidebar.taskmanager.boards",
              icon: "board",
              type: "item",
              link: "/app/task-manager/boards",
            },
            {
              name: "sidebar.taskmanager.workflowOverview",
              icon: "workflow",
              type: "item",
              link: "/app/task-manager/workflows",
            },
          ],
        },
        {
          name: "sidebar.onboarding",
          icon: "case",
          type: "item",
          link: "/app/onboarding",
        },
        {
          name: "sidebar.feedback",
          icon: "bookmark-outline",
          type: "item",
          link: "/app/feedbacks",
        },
        {
          name: "sidebar.customers",
          icon: "customers",
          type: "collapse",
          children: [
            {
              name: "sidebar.customers.addnew",
              icon: "new",
              type: "item",
              link: "/app/customers/customer-edit",
            },
            {
              name: "sidebar.customers",
              icon: "customers",
              type: "item",
              link: "/app/customers/company",
            },
            {
              name: "sidebar.customers.floorPlan",
              icon: "assignment-alert",
              type: "item",
              link: "/app/customers/floorPlan",
            },
          ],
        },
        {
          name: "sidebar.pages",
          icon: "book-image",
          type: "collapse",
          children: [
            {
              name: "sidebar.page.addnew",
              icon: "new",
              type: "item",
              link: "/app/page/add-new",
            },
            {
              name: "sidebar.pages",
              icon: "book-image",
              type: "item",
              link: "/app/page/list",
            },
          ],
        },
        {
          name: "dashboard.products",
          icon: "product",
          type: "collapse",
          children: [
            {
              name: "sidebar.products.addnew",
              icon: "new",
              type: "item",
              link: "/app/products/add-new",
            },
            {
              name: "sidebar.products.library",
              icon: "product-library",
              type: "item",
              link: "/app/products/libraries",
            },
            {
              name: "sidebar.products.delivery",
              icon: "delivery",
              type: "item",
              link: "/app/products/deliveries",
            },
            {
              name: "sidebar.products.datafeeds",
              icon: "main",
              type: "item",
              link: "/app/products/data-feeds",
            },
            {
              name: "sidebar.products.removeCategory",
              icon: "main",
              type: "item",
              link: "/app/products/remove-category",
            },
            // {
            //   name: "sidebar.products.inventory",
            //   icon: "shield-security",
            //   type: "item",
            //   link: "/app/products/inventory",
            // },
            // {
            //   name: "sidebar.products.addinventory",
            //   icon: "shopping-cart-plus",
            //   type: "item",
            //   link: "/app/products/add-inventory",
            // },
          ],
        },
        {
          name: "sidebar.forms",
          icon: "form",
          type: "collapse",
          children: [
            {
              name: "sidebar.form.addnew",
              icon: "new",
              type: "item",
              link: "/app/form/add-new",
            },
            {
              name: "sidebar.forms.overview",
              icon: "form-created",
              type: "item",
              link: "/app/form/list",
            },
          ],
        },
        {
          name: "sidebar.calendar",
          icon: "calendar",
          type: "item",
          link: "/app/calendar",
        },

        {
          name: "sidebar.servicedesk",
          icon: "service-desk",
          type: "collapse",
          children: [
            {
              name: "sidebar.helpdesk",
              icon: "helpdesk",
              type: "item",
              link: "/app/helpdesk",
            },
            /*      {
                   name: "sidebar.service",
                   icon: "contracts",
                   type: "item",
                   link: "/app/service",
                 }, */
          ],
        },
      ]);
    } else if (user.user_type == 1) {
      // super admin
      setNavigationMenus([
        /*     {
              name: "sidebar.dashboard",
              icon: "dashboard",
              type: "item",
              link: "/app/dashboard",
            }, */
        {
          name: "sidebar.customers.projects",
          icon: "projects",
          type: "collapse",
          children: [
            {
              name: "sidebar.project.addnew",
              icon: "new",
              type: "item",
              link: "/app/project/add-new",
            },
            {
              name: "sidebar.project.live",
              icon: "live-project",
              type: "item",
              count: project_cnt,
              link: "/app/project/live",
            },
          ],
        },
        {
          name: "sidebar.taskmanager.workflows",
          icon: "workflow",
          type: "collapse",
          children: [
            {
              name: "sidebar.taskmanager.addnew",
              icon: "new",
              type: "item",
              link: "/app/task-manager/add-new",
            },
            {
              name: "sidebar.taskmanager.mytasks",
              icon: "task",
              type: "item",
              count: task_cnt,
              count2: subtask_cnt,
              link: "/app/task-manager/my-tasks",
            },
            {
              name: "sidebar.taskmanager.boards",
              icon: "board",
              type: "item",
              link: "/app/task-manager/boards",
            },
            {
              name: "sidebar.taskmanager.workflowOverview",
              icon: "workflow",
              type: "item",
              link: "/app/task-manager/workflows",
            },
          ],
        },
        {
          name: "sidebar.customers",
          icon: "customers",
          type: "collapse",
          children: [
            {
              name: "sidebar.customers.addnew",
              icon: "new",
              type: "item",
              link: "/app/customers/customer-edit",
            },
            {
              name: "sidebar.customers",
              icon: "customers",
              type: "item",
              link: "/app/customers/company",
            },
          ],
        },
        {
          name: "dashboard.products",
          icon: "product",
          type: "collapse",
          children: [
            {
              name: "sidebar.products.addnew",
              icon: "new",
              type: "item",
              link: "/app/products/add-new",
            },
            {
              name: "sidebar.products.library",
              icon: "product-library",
              type: "item",
              link: "/app/products/libraries",
            },
            {
              name: "sidebar.products.delivery",
              icon: "delivery",
              type: "item",
              link: "/app/products/deliveries",
            },
            {
              name: "sidebar.products.inventry",
              icon: "manufacturer",
              type: "item",
              link: "/app/products/inventry",
            },
            {
              name: "sidebar.products.delivery_site",
              icon: "site",
              type: "item",
              link: "/app/products/delivery-sites",
            },
            {
              name: "sidebar.products.datafeeds",
              icon: "main",
              type: "item",
              link: "/app/products/data-feeds",
            },
          ],
        },
        {
          name: "sidebar.forms",
          icon: "form",
          type: "collapse",
          children: [
            {
              name: "sidebar.form.addnew",
              icon: "new",
              type: "item",
              link: "/app/form/add-new",
            },
            {
              name: "sidebar.forms.overview",
              icon: "form-created",
              type: "item",
              link: "/app/form/list",
            },
          ],
        },
        {
          name: "sidebar.calendar",
          icon: "calendar",
          type: "item",
          link: "/app/calendar",
        },
        {
          name: "sidebar.servicedesk",
          icon: "service-desk",
          type: "collapse",
          children: [
            {
              name: "sidebar.helpdesk",
              icon: "helpdesk",
              type: "item",
              link: "/app/helpdesk",
            },
            /*    {
                 name: "sidebar.service",
                 icon: "contracts",
                 type: "item",
                 link: "/app/service",
               }, */
          ],
        },
      ]);
    } else if (user.user_type < 4) {
      // engineer
      setNavigationMenus([
        /*   {
            name: "sidebar.dashboard",
            icon: "dashboard",
            type: "item",
            link: "/app/dashboard",
          }, */
        {
          name: "sidebar.customers.projects",
          icon: "projects",
          type: "collapse",
          children: [
            {
              name: "sidebar.project.addnew",
              icon: "new",
              type: "item",
              link: "/app/project/add-new",
            },
            {
              name: "sidebar.project.live",
              icon: "live-project",
              type: "item",
              count: project_cnt,
              link: "/app/project/live",
            },
          ],
        },
        {
          name: "sidebar.taskmanager.workflows",
          icon: "workflow",
          type: "collapse",
          children: [
            {
              name: "sidebar.taskmanager.addnew",
              icon: "new",
              type: "item",
              link: "/app/task-manager/add-new",
            },
            {
              name: "sidebar.taskmanager.mytasks",
              icon: "task",
              type: "item",
              count: task_cnt,
              count2: subtask_cnt,
              link: "/app/task-manager/my-tasks",
            },
            {
              name: "sidebar.taskmanager.boards",
              icon: "board",
              type: "item",
              link: "/app/task-manager/boards",
            },
            {
              name: "sidebar.taskmanager.workflowOverview",
              icon: "workflow",
              type: "item",
              link: "/app/task-manager/workflows",
            },
          ],
        },
        {
          name: "sidebar.customers",
          icon: "customers",
          type: "collapse",
          children: [
            {
              name: "sidebar.customers.addnew",
              icon: "new",
              type: "item",
              link: "/app/customers/customer-edit",
            },
            {
              name: "sidebar.customers",
              icon: "customers",
              type: "item",
              link: "/app/customers/company",
            },
          ],
        },
        {
          name: "dashboard.products",
          icon: "product",
          type: "collapse",
          children: [
            {
              name: "sidebar.products.addnew",
              icon: "new",
              type: "item",
              link: "/app/products/add-new",
            },
            {
              name: "sidebar.products.library",
              icon: "product-library",
              type: "item",
              link: "/app/products/libraries",
            },
            {
              name: "sidebar.products.datafeeds",
              icon: "main",
              type: "item",
              link: "/app/products/data-feeds",
            },
            // {
            //   name: "sidebar.products.inventory",
            //   icon: "shield-security",
            //   type: "item",
            //   link: "/app/products/inventory",
            // },
            // {
            //   name: "sidebar.products.addinventory",
            //   icon: "shopping-cart-plus",
            //   type: "item",
            //   link: "/app/products/add-inventory",
            // },
          ],
        },
        {
          name: "sidebar.forms",
          icon: "form",
          type: "collapse",
          children: [
            {
              name: "sidebar.form.addnew",
              icon: "new",
              type: "item",
              link: "/app/form/add-new",
            },
            {
              name: "sidebar.forms.overview",
              icon: "form-created",
              type: "item",
              link: "/app/form/list",
            },
          ],
        },
        {
          name: "sidebar.calendar",
          icon: "calendar",
          type: "item",
          link: "/app/calendar",
        },
        {
          name: "sidebar.servicedesk",
          icon: "service-desk",
          type: "collapse",
          children: [
            {
              name: "sidebar.helpdesk",
              icon: "helpdesk",
              type: "item",
              link: "/app/helpdesk",
            },
            /*     {
                  name: "sidebar.service",
                  icon: "contracts",
                  type: "item",
                  link: "/app/service",
                }, */
          ],
        },
      ]);
    } else if (user.user_type == 4) {
      setNavigationMenus([
        /*    {
             name: "sidebar.dashboard",
             icon: "dashboard",
             type: "item",
             link: "/app/dashboard",
           }, */
        {
          name: "sidebar.customers.projects",
          icon: "projects",
          type: "collapse",
          children: [
            {
              name: "sidebar.project.live",
              icon: "live-project",
              type: "item",
              count: project_cnt,
              link: "/app/project/live",
            },
          ],
        },
        {
          name: "sidebar.calendar",
          icon: "calendar",
          type: "item",
          link: "/app/calendar",
        },
      ]);
    } else {
      // end user
      setNavigationMenus([
        /*   {
            name: "sidebar.dashboard",
            icon: "dashboard",
            type: "item",
            link: "/app/dashboard",
          }, */
        // {
        //   name: "sidebar.customers.projects",
        //   icon: "projects",
        //   type: "item",
        //   link: "/app/project/live",
        //   count: project_cnt,
        // },
        {
          name: "sidebar.customers.projects",
          icon: "projects",
          type: "collapse",
          children: [
            {
              name: "sidebar.project.live",
              icon: "live-project",
              type: "item",
              count: project_cnt,
              link: "/app/project/live",
            },
          ],
        },
        {
          name: "sidebar.sites",
          icon: "site",
          type: "collapse",
          children: [
            {
              name: "sidebar.sites.site",
              icon: "site",
              type: "item",
              link: "/app/sites/mysite",
            },
            {
              name: "sidebar.sites.childcustomers",
              icon: "customers",
              type: "item",
              link: "/app/customers/company",
            },
          ],
        },
        {
          name: "sidebar.calendar",
          icon: "calendar",
          type: "item",
          link: "/app/calendar",
        },
        {
          name: "sidebar.servicedesk",
          icon: "service-desk",
          type: "collapse",
          children: [
            {
              name: "sidebar.helpdesk",
              icon: "helpdesk",
              type: "item",
              link: "/app/helpdesk",
            },
            /*       {
                    name: "sidebar.service",
                    icon: "contracts",
                    type: "item",
                    link: "/app/service",
                  }, */
          ],
        },
      ]);
    }
  };

  return (
    <CustomScrollbars className="scrollbar">
      <Navigation menuItems={navigationMenus} />
      {user.user_type <= 4 ? (
        <OnlineUsers />
      ) : (
        <LeftSidebarForEnduser customer={customer} />
      )}
    </CustomScrollbars>
  );
};

export default SideBarContent;
