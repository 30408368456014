import React from 'react';
import {useDispatch} from 'react-redux'
import {userSignOut} from 'actions/Auth';
import {useHistory} from 'react-router-dom';

const Logout = () => {
    const dispatch = useDispatch();
    dispatch(userSignOut());
    const History = useHistory();
    History.push('/');
    return (
        <div></div>
    )
}

export default Logout;

