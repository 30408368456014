import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const TaskManager = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/products`} />
    {/* <Route path={`${match.url}/my-tasks/:task_id`} component={asyncComponent(() => import('./routes/MyTasks/main.js'))} /> */}
    <Route
      path={`${match.url}/libraries`}
      component={asyncComponent(() => import("./routes/index.js"))}
    />
    <Route
      path={`${match.url}/add-new`}
      component={asyncComponent(() => import("./routes/AddProduct.js"))}
    />
    <Route
      path={`${match.url}/test`}
      component={asyncComponent(() => import("./routes/Test.js"))}
    />
    <Route
      path={`${match.url}/deliveries`}
      component={asyncComponent(() => import("./routes/PersistentDelivery.js"))}
    />
    <Route
      path={`${match.url}/inventry`}
      component={asyncComponent(() =>
        import("./routes/PersistentInventry.js")
      )}
    />
    <Route
      path={`${match.url}/delivery-sites`}
      component={asyncComponent(() =>
        import("./routes/PersistentDeliverySite.js")
      )}
    />
    <Route
      path={`${match.url}/data-feeds`}
      component={asyncComponent(() => import("./routes/PersistentDataFeed.js"))}
    />
    <Route
      path={`${match.url}/remove-category`}
      component={asyncComponent(() =>
        import("./routes/PersistentRemoveCategory.js")
      )}
    />
    <Route
      component={asyncComponent(() =>
        import("app/routes/extraPages/routes/404")
      )}
    />
  </Switch>
);

export default TaskManager;
