import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import UserInfo from 'components/UserInfo';
import { COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION } from 'constants/ActionTypes';
import { toggleCollapsedNav, updateWindowWidth, setDrawerMouseOver } from 'actions/Setting';
import { onShowSidebar, onHideSidebar } from 'actions/Other';
import SideBarContent from "./SideBarContent";

const SideBar = () => {
    const dispatch = useDispatch();
    const { navCollapsed, drawerType, width, navigationStyle } = useSelector(({ settings }) => settings);
    const { sidebar_expand } = useSelector(({ other }) => other)

    const onToggleCollapsedNav = (e) => {
        dispatch(toggleCollapsedNav());
    };

    let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex';
    let type = 'permanent';
    if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
        type = 'temporary';
    }

    if (navigationStyle === HORIZONTAL_NAVIGATION) {
        drawerStyle = '';
        type = 'temporary';
    }

    const handleSidebarShow = () => {
        dispatch(onShowSidebar())
    }

    const handleSidebarHide = () => {
        dispatch(onHideSidebar())
    }

    return (
        <>
            {sidebar_expand ?
                <div className={`app-sidebar position-relative d-none ${drawerStyle}`}>
                    <Drawer className="app-sidebar-content"
                        variant={type}
                        open={type.includes('temporary') ? navCollapsed : true}
                        onClose={onToggleCollapsedNav}
                        classes={{
                            paper: 'side-nav',
                        }}
                    >
                        <UserInfo />
                        <SideBarContent />
                    </Drawer>
                    <div className='sidebar-box' style={{ right: '-25px' }}>
                        <img src="/icons-left-24.png" onClick={handleSidebarHide} />
                    </div>
                </div>
                :
                <div className='app-sidebar-reduce'>
                    <div className='sidebar-box'>
                        <img src="/icons-right-24.png" onClick={handleSidebarShow} />
                    </div>
                </div>
            }
        </>

    );
};


export default withRouter(SideBar);

