import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { base_url, public_url, $http } from "config";
import IconButton from "@material-ui/core/IconButton";
import { Helmet } from "react-helmet";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import IntlMessages from "util/IntlMessages";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import { Modal, ModalHeader } from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import { Countries } from "../util/constants.js";
import IntegrationReactOutlineSelect from "components/autoComplete/reactSelect/IntegrationReactOutlineSelect";
import axios from "axios";
import {
  hideMessage,
  showAuthLoader,
  /*  userFacebookSignIn,
    userGithubSignIn,
    userGoogleSignIn, */
  userSignIn,
  /* userTwitterSignIn */
} from "actions/Auth";

const SignIn = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [onboarding_open, setOnboardingOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [telephone, setTelephone] = useState("");
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(
    ({ auth }) => auth
  );

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }

    if (authUser !== null && authUser !== undefined) {
      props.history.push("/");
    }
  }, [showMessage, authUser, props.history]);

  const saveNewOnboardingCustomer = () => {
    const frmData = new FormData();

    frmData.append("firstName", firstName);
    frmData.append("lastName", lastName);
    frmData.append("company", company);
    frmData.append("email", email);
    frmData.append("phone", telephone);
    frmData.append("password", password);

    setOnboardingOpen(false);

    $http
      .post(`${base_url}onboarding/addNewOnboardingCustomer`, frmData)
      .then((response) => {
        if (response.data.status === "success") {
          dispatch(showAuthLoader());
          dispatch(userSignIn({ email, password }));
        } else if (response.data.status === "error") {
          NotificationManager.error(
            response.data.msg,
            "Error!",
            1000,
            () => { }
          );
        }
      })
      .catch((error) => {
        NotificationManager.error(error, "Error!", 1000, () => { });
      });
  };

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content inline-middle justify-content-center">
          <Link className="logo-lg" to="/" title="Jambo">
            <img
              src={require("assets/images/logo-signin.png")}
              alt="jambo"
              title="jambo"
            />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>
              <IntlMessages id="appModule.signin" />
            </h1>
          </div>

          <div className="app-login-form">
            <form>
              <fieldset>
                <TextField
                  label={<IntlMessages id="appModule.email" />}
                  fullWidth
                  onChange={(event) => setEmail(event.target.value)}
                  defaultValue={email}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />
                <TextField
                  type="password"
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  onChange={(event) => setPassword(event.target.value)}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      dispatch(showAuthLoader());
                      dispatch(userSignIn({ email, password }));
                    }
                  }}
                  defaultValue={password}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />
                <div className="mt-1 mb-2 d-flex justify-content-between align-items-center">
                  <FormControlLabel
                    control={<Checkbox color="primary" value="gilad" />}
                    label={<IntlMessages id="appModule.rememberMe" />}
                  />

                  <div>
                    <Link to="/forgot" title="Reset Password">
                      <IntlMessages id="appModule.forgotPassword" />
                    </Link>
                  </div>
                </div>

                <div className="mb-3 inline-middle justify-content-between">
                  <Button
                    onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userSignIn({ email, password }));
                    }}
                    variant="contained"
                    color="primary"
                    className="text-capitalize"
                  >
                    <IntlMessages id="appModule.signIn" />
                  </Button>
                  {/* <Button
                    onClick={() => setOnboardingOpen(true)}
                    variant="contained"
                    color="primary"
                    className="text-capitalize"
                  >
                    Sign up
                  </Button> */}
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      {loader && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
      {showMessage && NotificationManager.error(alertMessage)}
      <Modal
        key="schedule-save"
        className="modal-box modal-body"
        toggle={() => setOnboardingOpen(false)}
        isOpen={onboarding_open}
      >
        <div className="modal-box-content">
          <Paper className="d-flex flex-column text-center px-5 py-4">
            <Typography variant="h4" color="inherit" noWrap className="mb-3">
              Start your Free 14 days trial
            </Typography>
            <h3 className="mb-1 text-light-grey">
              No obligation. No commitment.
            </h3>
            <h3 className="text-light-grey">
              No Credit Card Details Required.
            </h3>
            <div className="d-flex justify-content-between">
              <TextField
                margin="dense"
                variant="outlined"
                label="First Name"
                value={firstName}
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
                className="mr-5"
                required
                fullWidth
              />
              <TextField
                margin="dense"
                variant="outlined"
                label="Last Name"
                value={lastName}
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
                required
                fullWidth
              />
            </div>
            <TextField
              margin="dense"
              variant="outlined"
              label="Company Name"
              value={company}
              onChange={(event) => {
                setCompany(event.target.value);
              }}
              required
              fullWidth
            />
            <TextField
              margin="dense"
              variant="outlined"
              label="Telephone"
              value={telephone}
              onChange={(event) => {
                setTelephone(event.target.value);
              }}
              required
              fullWidth
            />
            <TextField
              margin="dense"
              variant="outlined"
              label="Email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              required
              fullWidth
            />
            <TextField
              margin="dense"
              variant="outlined"
              label="Password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              required
              fullWidth
            />
          </Paper>
        </div>
        <div className="modal-box-footer text-center">
          <Button
            variant="contained"
            className="bg-success onboarding-jr-btn"
            onClick={() => saveNewOnboardingCustomer()}
            size="large"
          >
            Start your Free Trial Now
          </Button>
        </div>
      </Modal>

      <NotificationContainer />
    </div>
  );
};

export default SignIn;
