import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "config";
import configureStore, { history } from "./store";
//import './firebase/firebase';
import App from "./containers/App";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const store = configureStore();

const MainApp = () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </ConnectedRouter>
      </Provider>
    </MsalProvider>
  );
};

export default MainApp;
