import {
  ON_SHOW_SIDEBAR,
  ON_HIDE_SIDEBAR
} from "constants/ActionTypes";

const INIT_STATE = {
  sidebar_expand: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_SHOW_SIDEBAR: {
      return {
        ...state,
        sidebar_expand: true,
      };
    }
    case ON_HIDE_SIDEBAR: {
      return {
        ...state,
        sidebar_expand: false,
      };
    }
    default:
      return state;
  }
};
