import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const TaskManager = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/my-tasks`} />
    <Route
      path={`${match.url}/my-tasks/:task_id`}
      component={asyncComponent(() => import("./routes/MyTasks/Main.js"))}
    />
    <Route
      path={`${match.url}/my-tasks`}
      component={asyncComponent(() => import("./routes/MyTasks/Main.js"))}
    />
    {/* <Route
      path={`${match.url}/boards`}
      component={asyncComponent(() =>
        import("./routes/MyTasks/TaskListBoard.js")
      )}
    /> */}
    <Route
      path={`${match.url}/boards`}
      component={asyncComponent(() =>
        import("./routes/WorkflowBoards")
      )}
    />
    <Route
      path={`${match.url}/calendar`}
      component={asyncComponent(() =>
        import("./routes/WorkflowCalendar")
      )}
    />
    <Route
      path={`${match.url}/workflows`}
      component={asyncComponent(() => import("./routes/Workflows"))}
    />
    {/* <Route path={`${match.url}/boards`} component={asyncComponent(() => import('./routes/WorkflowBoards'))} />
    <Route path={`${match.url}/calendar`} component={asyncComponent(() => import('./routes/WorkflowCalendar'))} /> */}
    <Route
      path={`${match.url}/add-new`}
      component={asyncComponent(() => import("./routes/AddNew"))}
    />
    <Route
      component={asyncComponent(() =>
        import("app/routes/extraPages/routes/404")
      )}
    />
  </Switch>
);

export default TaskManager;
