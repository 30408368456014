import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const TaskManager = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/mysite`} />
    <Route
      path={`${match.url}/mysite`}
      component={asyncComponent(() => import("./routes/site"))}
    />
    <Route
      component={asyncComponent(() =>
        import("app/routes/extraPages/routes/404")
      )}
    />
  </Switch>
);

export default TaskManager;
