import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import Button from "@material-ui/core/Button";
import { base_url, public_url, $http } from "config";
import { array_task_cache } from "../../../../app/routes/Offline/CacheManage";

const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    // margin: theme.spacing.unit * 1,
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: grey[50],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

class TaskCache extends React.Component {
  state = {
    loading: false,
    success: false,
  };
  handleButtonClick = () => {
    if (!this.state.loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
          $http.get(`${base_url}task/taskList`).then((response) => {
            array_task_cache(
              response.data.tasks,
              this.props.setCount,
              this.props.setName
            ).then((response) => {
              this.setState({
                loading: false,
                success: true,
              });
            });
          });
        }
      );
    }
  };
  timer = undefined;

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { loading, success } = this.state;
    const { classes } = this.props;
    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <div className={classes.root}>
        <div className={classes.wrapper} className="ml-auto my-2">
          <Button
            variant="contained"
            color="primary"
            className={buttonClassname}
            disabled={loading}
            onClick={this.handleButtonClick}
          >
            {success ? `Done` : `Update`}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </div>
    );
  }
}

TaskCache.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TaskCache);
