import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from "@material-ui/core/IconButton";


const CardEditMenu = (props) => {

    const [menuState, setMenuState] = useState(false);
    const [anchorEl, setAnchorEl] = useState(undefined);
    const { selectedData, onSelectRow, menu_rows } = props;
    const options = menu_rows ? menu_rows : ([
        // {content: 'View', id: 0},
        // {content: 'Modify', id: 1},
        // {content: 'Delete', id: 2}
        { content: 'Edit', id: 0 },
        { content: 'Delete', id: 1 }
    ]);

    const onOptionMenuSelect = event => {
        setMenuState(true);
        setAnchorEl(event.currentTarget);
    };

    const handleRequestClose = () => {
        setMenuState(false);
    };

    return (
        <div>
            <IconButton onClick={onOptionMenuSelect}>
                <i className="zmdi zmdi-more-vert" /></IconButton>

            <Menu
                anchorEl={anchorEl}
                open={menuState}
                onClose={handleRequestClose}
                key={selectedData.id}
                MenuListProps={{
                    style: {
                        width: 150,
                        paddingTop: 0,
                        paddingBottom: 0
                    },
                }}>

                {options.map(option =>
                    <MenuItem key={selectedData + "-" + option.content}
                        onClick={() => {
                            handleRequestClose();
                            onSelectRow(selectedData, option.id);
                        }}>
                        {option.content}
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
};

export default CardEditMenu;
