import { base_url, public_url, $http } from "config";
const me = JSON.parse(localStorage.getItem("user"));

const array_other_cache = async (files, setCount, setName) => {
  let total = files.length;
  let count = 0;
  for (const file of files) {
    count++;
    setCount((100 * count) / total);
    setName(file);
    if (count % 10 == 0) {
      await $http.get(`${public_url}${file}`);
    } else {
      $http.get(`${public_url}${file}`);
    }
  }
  await $http.get(`${public_url}upload/img/${me.back_cover}`);
  await $http.get(`${public_url}upload/img/${me.front_cover}`);
  $http.get(`${base_url}page/pageList`);
  $http.get(`${base_url}form/infoForm`);
  $http.get(`${base_url}calendar/getCalendarEvents`);
  $http.get(`${base_url}user/userInfo`);
  $http.get(`${base_url}user/getProjectTaskCount`);
  $http.get(`${base_url}company/getCompanyInfo`);
  $http.get(`${base_url}company/partnerlist`);
  $http.get(`${base_url}product/labelList`);
  $http.get(`${base_url}task/labelList`);
  $http.get(`${base_url}page/labelList`);
  $http.get(`${base_url}project/getProjectModule`);
  $http.get(`${base_url}product/getQrOption`);
  $http.get(`${base_url}product/productList`);
  $http.get(`${base_url}user/totalUserlist`);
  $http.get(`${public_url}app/calendar`);
  $http.get(`${public_url}app/settings/profile`);
  $http.get(`${public_url}app/settings/company/settings`);
  $http.get(`${public_url}app/settings/products`);
  $http.get(`${public_url}app/settings/users`);
  $http.get(`${public_url}app/settings/stickers/add-new`);
  $http.get(`${public_url}app/settings/stickers/stickers`);
  $http.get(`${public_url}app/settings/stickers/categories`);
};

const array_project_cache = async (projects, setCount, setName) => {
  await $http.get(`${base_url}project/projectList?archived=0`);
  await $http.get(`${base_url}project/projectList?archived=1`);
  $http.get(`${public_url}app/project/live`);
  let total = projects.length;
  let count = 0;
  for (const project of projects) {
    count++;
    setCount((100 * count) / total);
    setName(project.project_name);

    let response = await $http.get(
      `${base_url}project/projectInfo?project_id=${project.id}`
    );
    $http.get(`${public_url}app/project/live/${project.id}`);
    let logo_img = response.data.project.logo_img;
    if (logo_img) {
      $http.get(`${public_url}upload/img/${logo_img}`);
    }

    for (const room of response.data.rooms) {
      for (const img of room.img_files) {
        // add the room images
        $http.get(`${public_url}upload/img/${img.img_name}`);
      }
    }

    $http.get(`${base_url}calendar/getCalendarEvents?project_id=${project.id}`);
    $http.get(`${base_url}chat/getChats?project_id=${project.id}`);
    $http.get(`${base_url}project/getActivity?project_id=${project.id}`);
    $http.get(`${base_url}task/taskList?project_id=${project.id}`);
    $http.get(`${base_url}room/roomInfo?project_id=${project.id}`);
    $http.get(`${base_url}product/getProductInfo?project_id=${project.id}`);
    $http.get(`${base_url}company/getRackShelfs?project_id=${project.id}`);
  }
  $http.get(`${base_url}document/documentList`);
  $http.get(`${public_url}app/project/sticker`);
};

const array_room_cache = async (rooms, setCount, setName) => {
  let total = rooms.length;
  let count = 0;
  for (const room of rooms) {
    count++;
    await $http.get(
      `${base_url}room/roomInfo?id=${room.room_id}&project_id=${room.project_id}`
    );
    setCount((100 * count) / total);
    setName(room.room_number);
    $http.get(
      `${public_url}app/project/live/${room.project_id}/${room.room_id}`
    );
    $http.get(
      `${public_url}app/project/live/${room.project_id}/${room.room_id}/modify`
    );
    $http.get(
      `${base_url}calendar/getCalendarEvents?project_id=${room.project_id}&room_id=${room.room_id}`
    );
    $http.get(
      `${base_url}task/taskList?project_id=${room.project_id}&room_id=${room.room_id}`
    );
  }
  $http.get(`${base_url}room/editPhoto`);
  $http.get(`${base_url}category/getCategoryInfo`);
  $http.get(`${base_url}category/categoryList`);
};

const array_task_cache = async (tasks, setCount, setName) => {
  let total = tasks.length;
  let count = 0;
  $http.get(`${public_url}app/task-manager/my-tasks`);

  for (const task of tasks) {
    count++;
    setCount((100 * count) / total);
    setName(task.task);
    $http.get(`${public_url}app/task-manager/my-tasks/${task.id}`);

    if (task.task_img) {
      await $http.get(`${public_url}upload/img/${task.task_img}`);
    }
  }
  $http.get(`${base_url}calendar/getCalendarEvents?onlyTask=true`);
};

const array_user_cache = async (users) => {
  for (const user of users) {
    if (user.profile_pic) {
      await $http.get(`${public_url}upload/img/${user.profile_pic}`);
    }
  }
};

const array_customer_cache = async (customers, setCount, setName) => {
  let total = customers.length;
  let count = 0;
  $http.get(`${public_url}app/customers/company`);
  for (const customer of customers) {
    count++;
    setCount((100 * count) / total);
    setName(customer.name);

    if (customer.logo_img) {
      $http.get(`${public_url}upload/img/${customer.logo_img}`);
    }

    $http.get(`${public_url}app/customers/company/${customer.id}`);
    let response = await $http.get(
      `${base_url}customers/companyInfo?id=${customer.id}`
    );

    for (const user of response.data.users) {
      $http.get(`${base_url}user/userInfo?id=${user.id}`);
    }

    // for (const site of response.data.sites) {
    //     let site_res = await $http.get(`${base_url}site/siteInfo?id=${site.id}`)
    //     $http.get(`${public_url}sites/mysite/${site.id}`)
    //     for (const building of site_res.data.buildings) {
    //         $http.get(`${base_url}building/buildingInfo?id=${building.id}`)
    //         $http.get(`${public_url}sites/mysite/${site.id}/${building.id}`)
    //     }
    // }

    $http.get(`${base_url}customers/company/customer-edit?id=${customer.id}`);
    $http.get(`${base_url}customers/partnerList?id=${customer.id}`);
    $http.get(`${base_url}task/taskList?customer_id=${customer.id}`);
  }

  $http.get(`${base_url}project/projectInfo`);
  $http.get(`${base_url}product/getProductInfo`);
  $http.get(`${base_url}task/getTaskInfo`);
};

const array_product_cache = async (products, setCount, setName) => {
  let total = products.length;
  let count = 0;
  for (const product of products) {
    count++;
    setCount((100 * count) / total);
    setName(product.product_name);

    await $http.get(`${base_url}product/getProductInfo?id=${product.id}`);
  }
};

const project_cache = async (Project, setCount, setName) => {
  let response = await $http.get(
    `${base_url}project/projectInfo?project_id=${Project.id}`
  );
  $http.get(`${base_url}room/roomInfo?project_id=${Project.id}`);
  $http.get(`${base_url}product/getProductInfo?project_id=${Project.id}`);

  $http.get(`${base_url}room/editPhoto`);
  $http.get(`${base_url}category/getCategoryInfo`);
  $http.get(`${base_url}category/categoryList`);
  $http.get(`${base_url}document/documentList`);
  $http.get(`${public_url}app/project/sticker`);
  if (response.data.rooms.length == 0 && response.data.products.length == 0)
    return;
  let total = response.data.rooms.length + response.data.products.length;
  let count = 0;
  for (const room of response.data.rooms) {
    count++;
    setCount((100 * count) / total);
    setName(room.room_number);
    for (const img of room.img_files) {
      // add the room images
      await $http.get(`${public_url}upload/img/${img.img_name}`);
    }
    await $http.get(
      `${base_url}room/roomInfo?id=${room.id}&project_id=${room.project_id}`
    );
    $http.get(`${public_url}app/project/live/${room.project_id}/${room.id}`);
    $http.get(
      `${public_url}app/project/live/${room.project_id}/${room.id}/modify`
    );
    $http.get(
      `${base_url}calendar/getCalendarEvents?project_id=${room.project_id}&room_id=${room.id}`
    );
    $http.get(
      `${base_url}task/taskList?project_id=${room.project_id}&room_id=${room.id}`
    );
  }

  for (const product of response.data.products) {
    count++;
    setCount((100 * count) / total);
    setName(product.product_name);
    await $http.get(`${base_url}product/getProductInfo?id=${product.id}`);
  }
};

const other_cache = async (files, setCount, setName) => {
  let total = files.length;
  let count = 0;
  for (const file of files) {
    count++;
    setCount((100 * count) / total);
    setName(file);
    if (count % 10 == 0) {
      await $http.get(`${public_url}${file}`);
    } else {
      $http.get(`${public_url}${file}`);
    }
  }
};

export {
  array_other_cache,
  array_project_cache,
  array_room_cache,
  array_task_cache,
  array_user_cache,
  array_customer_cache,
  array_product_cache,
  project_cache,
  other_cache,
};
