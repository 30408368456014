import React from 'react';
import {Route, Switch} from 'react-router-dom';

import asyncComponent from '../../../util/asyncComponent';

const FormBuilder = ({match}) => (
    <Switch>
        <Route path={`${match.url}/list`}
                component={asyncComponent(() => import('./routes/FormBuilder'))}/>
        <Route path={`${match.url}/add-new`}
                component={asyncComponent(() => import('./routes/AddNew'))}/>
        <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
);

export default FormBuilder;
