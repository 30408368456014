import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link, useParams } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import AttachFile from '@material-ui/icons/AttachFile';
import axios from "axios";
import { base_url, public_url } from "../config";
import { useHistory } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';

const CompanyUserSignUp = (props) => {
    const History = useHistory();
    const params = useParams();
    const [first_name, setFirstName] = useState("");
    const [user_id, setUserId] = useState(0)
    const [last_name, setLastName] = useState("");
    const [job_title, setJobTitle] = useState("");
    const [mobile, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [profile_pic, setProfilePic] = useState(null);
    const [ProfilePicUrl, setProfilePicUrl] = useState('https://via.placeholder.com/300x300')
    const [register_flag, setRegisterFlag] = useState(0)

    useEffect(() => {
        axios.post(base_url + "user/checkValidate", { invite_code: params.invite_code })
            .then((response) => {
                if (response.data.status === "success") {
                    setFirstName(response.data.user.first_name)
                    setUserId(response.data.user.id)
                    setEmail(response.data.user.email)
                    //setJobTitle(response.data.user.user_type)
                    setRegisterFlag(1)
                }
                else {
                    // History.push("/")
                    window.location.href = `${public_url}signin`
                }
            })
            .catch((error) => error)

    }, []);
    const registerUser = () => {
        const userData = new FormData();
        userData.append('id', user_id);
        userData.append('email', email);
        userData.append('password', password);
        userData.append('first_name', first_name);
        userData.append('last_name', last_name);
        userData.append('job_title', job_title);
        userData.append('mobile', mobile);
        userData.append('profile_pic', profile_pic);
        axios.post(base_url + "companyuser/register", userData)
            .then((response) => {
                if (response.data.status === "success") {
                    // History.push("/")
                    window.location.href = `${public_url}signin`
                }
                else {
                    NotificationManager.error("You must input data in the field!");
                }
            })
    }
    const changeUserImg = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            setProfilePic(file);
            setProfilePicUrl(reader.result);
        }
        reader.readAsDataURL(file)
    }

    return (

        <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            {register_flag ? (
                <div className="app-login-main-content">
                    <div className="app-logo-content inline-middle justify-content-center">
                        <Link className="logo-lg" to="/" title="Jambo">
                            <img
                                src={require("assets/images/logo-signin.png")}
                                alt="jambo"
                                title="jambo"
                            />
                        </Link>
                    </div>

                    <div className="app-login-content">
                        <div className="app-login-header">
                            <h1>Company User Sign Up</h1>
                        </div>

                        <div className="app-login-form">
                            <form method="post" action="/">

                                <div className="d-flex flex-column align-items-center">
                                    <div className="jr-card-thumb m-auto size-120">
                                        <img className="card-img-top img-fluid" alt="products" src={ProfilePicUrl} />
                                    </div>
                                    <input type="file" id="profile_img" accept="image/*"
                                        name="profile_img" style={{ display: "none" }}
                                        onChange={(event) => changeUserImg(event)} />

                                    <div className="mt-2 inline-middle">
                                        <div className="mr-2 new-modal-lable">
                                            User Profile
                                        </div>
                                        <Fab className="jr-fab-btn bg-primary size-40 text-white"
                                            onClick={(e) => { document.getElementById('profile_img').click() }}>
                                            <i className="zmdi zmdi-cloud-upload zmdi-hc-2x" />
                                        </Fab>
                                    </div>
                                </div>
                                <TextField
                                    type="text"
                                    label="First Name"
                                    onChange={(event) => setFirstName(event.target.value)}
                                    fullWidth
                                    defaultValue={first_name}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                />

                                <TextField
                                    type="text"
                                    label="Last Name"
                                    onChange={(event) => setLastName(event.target.value)}
                                    fullWidth
                                    defaultValue={last_name}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                />

                                <TextField
                                    type="text"
                                    label="Job Title"
                                    onChange={(event) => setJobTitle(event.target.value)}
                                    fullWidth
                                    defaultValue={job_title}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                />

                                <TextField
                                    type="text"
                                    label="Phone Number"
                                    onChange={(event) => setPhoneNumber(event.target.value)}
                                    fullWidth
                                    defaultValue={mobile}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                />

                                <TextField
                                    type="email"
                                    onChange={(event) => setEmail(event.target.value)}
                                    label={<IntlMessages id="appModule.email" />}
                                    fullWidth
                                    defaultValue={email}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                    disabled
                                />

                                <TextField
                                    type="password"
                                    onChange={(event) => setPassword(event.target.value)}
                                    label={<IntlMessages id="appModule.password" />}
                                    fullWidth
                                    defaultValue={password}
                                    margin="normal"
                                    className="mt-0 mb-4"
                                />

                                {/* <div className="mb-3 inline-middle justify-content-between">
                                    <input type="file" id="profile_img" accept="image/*" 
                                        name="profile_img" style={{display: "none"}}
                                        onChange={(event) => setProfilePic(event.target.files[0])}/>
                                    <Button className="attach-file jr-btn text-muted" 
                                        variant="contained" onClick={(e)=>{document.getElementById('profile_img').click()}}>
                                        <AttachFile/> Profile Picture
                                    </Button>
                                  
                                </div> */}
                                <div className="mb-3 inline-middle justify-content-between">
                                    <Button variant="contained" onClick={() => {
                                        registerUser()
                                    }} color="primary">
                                        <IntlMessages
                                            id="appModule.regsiter" />
                                    </Button>
                                    <Link to="/signin">
                                        <IntlMessages id="signUp.alreadyMember" />
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )
                :
                (
                    <div></div>
                )
            }


            <NotificationContainer />
        </div>
    )
};


export default CompanyUserSignUp;
