import {
  ON_SHOW_SIDEBAR,
  ON_HIDE_SIDEBAR
} from "constants/ActionTypes";

export const onShowSidebar = () => {
  return {
    type: ON_SHOW_SIDEBAR
  };
};

export const onHideSidebar = () => {
  return {
    type: ON_HIDE_SIDEBAR
  };
};