import React, { useState, useEffect } from "react";
import { base_url, public_url, $http } from "config";
import Button from "@material-ui/core/Button";
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import ReactPlayer from "react-player/lazy";
import { Modal } from "reactstrap";

const NotificationCell = (props) => {
    const { data, handleCloseNoti } = props
    const nowuser = JSON.parse(localStorage.getItem("user"));
    const [hover_flag, setHoverFlag] = useState(false)
    const [show_video, setShowVideo] = useState(false)

    return (<div className="rounded p-3 mb-2 d-flex position-relative" style={{ background: data.background_color }}>
        <div className="jr-card-thumb p-2">
            <div
                className="sirvez-video-thumbnail"
                onMouseEnter={() => setHoverFlag(true)}
                onMouseLeave={() => setHoverFlag(false)}
            >
                <div className="sirvez-thumbnail-img" style={{ filter: `${hover_flag ? 'brightness(0.5)' : 'brightness(1)'}`, transition: '0.5s all ease' }}>
                    {data.video_url ?
                        <video width="100%" controls="controls" preload="metadata">
                            <source src={`${public_url}upload/img/${data.video_url}#t=0.5`} type="video/mp4" />
                        </video>
                        :
                        <img className="image-fluid w-100" src={`${public_url}upload/img/empty_noti.png`} alt="notification-thumbnail" />}
                </div>
                <div className="youtube-play-icon text-light-blue" onClick={() => setShowVideo(true)}>
                    {/* <i className="zmdi zmdi-youtube-play"></i> */}
                    {hover_flag &&
                        <div className="rounded bg-blue text-white px-2" style={{ cursor: 'pointer' }}>
                            <PlayArrowRoundedIcon fontSize="large" />
                        </div>
                    }
                </div>
            </div>
        </div>
        <div className="text-white d-flex flex-column justify-content-center px-3">
            <div className="text-right position-absolute" style={{ right: 20, top: 10 }}>
                <Button className="text-capitalize text-white" onClick={() => handleCloseNoti(data.id)}>Remind Me Later</Button>
                <span className="ml-2">|</span>
                <Button className="text-capitalize text-white" onClick={() => handleCloseNoti(data.id)}>Close</Button>
            </div>
            {data.notification_title && <h1 className="mb-1 text-capitalize">{data.notification_title}</h1>}
            {data.main_title && <h4 className="mb-1">{`Hi ${nowuser.first_name.charAt(0).toUpperCase() + nowuser.first_name.slice(1)}, ${data.main_title}`}</h4>}
        </div>

        <Modal
            id="noti-modal"
            key="customer-save"
            className="comment_modal modal-box noti-modal"
            toggle={() => setShowVideo(false)}
            isOpen={show_video}
        >
            <div className="row">
                <div className="col-12">
                    <ReactPlayer
                        url={data.video_url}
                        className="react-player"
                        controls={true}
                        width="100%"
                        height="100%"
                    />
                </div>
            </div>
        </Modal>
    </div>)
}

export default NotificationCell;