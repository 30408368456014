import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  DialogContent,
  LinearProgress,
  Tooltip,
} from "@material-ui/core";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { COLLAPSED_DRAWER, FIXED_DRAWER } from "constants/ActionTypes";
import AppNotification from "../../../../components/AppNotification";
import CardHeader from "components/dashboard/Common/CardHeader/index";
import { StringToColor } from "util/functions";
import {
  switchLanguage,
  toggleCollapsedNav,
  setOnlineUsers,
} from "actions/Setting";
import IntlMessages from "util/IntlMessages";
import Feedback from "../Feedback";
import Echo from "laravel-echo";
import { pusher, public_url, $http, base_url, sendNotification } from "config";
import { useHistory } from "react-router-dom";
import JavascriptCache from "./JavascriptCache";
import ProjectCache from "./ProjectCache";
import CustomerCache from "./CustomerCache";
import LocationCache from "./LocationCache";
import TaskCache from "./TaskCache";
import ProductCache from "./ProductCache";
import { userSignOut } from "actions/Auth";
import { getResponsePWA } from "config";

const Index = (props) => {
  const dispatch = useDispatch();
  const History = useHistory();
  const { drawerType, navCollapsed } = useSelector(({ settings }) => settings);
  const { sidebar_expand } = useSelector(({ other }) => other)
  const [appNotification, setAppNotification] = useState(false);
  const [snackbar_flag, setSnackbarRead] = useState(false);
  const [message, setMessage] = useState("");
  const [project_id, setProjectId] = useState("");
  const nowuser = JSON.parse(localStorage.getItem("user"));
  const [modal_cache, setModalCache] = useState(false);
  const [js_cnt, setJsCnt] = useState(0);
  const [js_name, setJsName] = useState("");
  const [customer_cnt, setCustomerCnt] = useState(0);
  const [customer_name, setCustomerName] = useState("");
  const [project_cnt, setProjectCnt] = useState(0);
  const [project_name, setProjectName] = useState("");
  const [location_cnt, setLocationCnt] = useState(0);
  const [location_name, setLocationName] = useState("");
  const [product_cnt, setProductCnt] = useState(0);
  const [product_name, setProductName] = useState("");
  const [task_cnt, setTaskCnt] = useState(0);
  const [task_name, setTaskName] = useState("");

  const [settings, setSettings] = useState(false);
  const [profile, setProfile] = useState(false);

  //const {onlineUsers} = useSelector(({settings}) => settings);

  const onAppNotificationSelect = () => {
    setAppNotification(!appNotification);
  };

  const onToggleCollapsedNav = (e) => {
    const val = !navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };
  const handleRequestClose = () => {
    History.push({
      pathname: `/app/project/live/${project_id}`,
    });
    setMessage("");
    setProjectId("");
    setSnackbarRead(false);
  };

  useEffect(() => {
    const echo = new Echo({
      broadcaster: "pusher",
      //authEndpoint: `${public_url}broadcasting/auth`,
      key: pusher.key,
      cluster: pusher.cluster,
      encrypted: true,
      auth: {
        headers: {
          "X-Auth-Token": localStorage.getItem("token"),
        },
      },
    });

    echo
      .join("online-user")
      .here((users) => {
        localStorage.setItem("onlineUsers", JSON.stringify(users));
      })

      .joining((user) => {
        let onlineUsers = JSON.parse(localStorage.getItem("onlineUsers"));
        if (onlineUsers.filter((item) => item.id == user.id).length > 0) return;
        onlineUsers.push(user);
        localStorage.setItem("onlineUsers", JSON.stringify(onlineUsers));
      })

      .leaving((user) => {
        let onlineUsers = JSON.parse(localStorage.getItem("onlineUsers"));
        let updateUsers = onlineUsers.filter((item) => item.id != user.id);
        localStorage.setItem("onlineUsers", JSON.stringify(updateUsers));
      });
    echo.join("chat").listen(".send_message", (event) => {
      let onlineUsers = JSON.parse(localStorage.getItem("user"));

      if (event.chat.message == "null" || event.chat.message == null) return;
      if (event.chat.receiver_id == nowuser.id) {
        let current_url = localStorage.getItem("current_url");
        if (current_url != "/app/project/live/" + event.chat.project_id) {
          setMessage(event.chat.send_user + " : " + event.chat.message);
          setProjectId(event.chat.project_id);
          setSnackbarRead(true);
        }
      }
    });

    echo.join("notification").listen(".notification", (event) => {
      let note = event.notification;

      if (note.created_by == nowuser.id) return;
      try {
        if (note.type == "delivery") {
          if (note.delivery && note.delivery.company_id == nowuser.company_id) {
            sendNotification(
              note.title,
              note.text1,
              note.image,
              note.id,
              note.action_link,
              note.background
            );
          }
        } else if (note.user_id && note.user_id.includes(nowuser.id)) {
          let text = `${note.text1}${nowuser.first_name} ${nowuser.last_name}${note.text2}`;
          sendNotification(
            note.title,
            text,
            note.image,
            note.id,
            note.action_link,
            note.background
          );
        } else if (!note.user_id) {
          console.log("errr", note);
        }
      } catch (e) {
        console.log("notification err", e, note);
      }
    });

    echo.join("helpdesk").listen(".helpdesk_ticket", (event) => {
      if (nowuser.company_id == event.helpdesk.company_id) {
        sendNotification(
          "Created a new Helpdesk ticket",
          `New Helpdesk ticket[${event.helpdesk.helpdesk}] has been created by ${event.helpdesk.mail_fr}`,
          null,
          `helpdesk-${event.helpdesk.id}`,
          `${public_url}app/helpdesk/my-helpdesk/${event.helpdesk.id}`,
          null
        );
      }
    });

    const onlineUsers = localStorage.hasOwnProperty("onlineUsers")
      ? JSON.parse(localStorage.getItem("onlineUsers"))
      : [];
    const online_ids = onlineUsers.map((item) => item.id);

    getResponsePWA(
      `${base_url}user/onlineUsers?ids=${JSON.stringify(online_ids)}`,
      updatedOnlineUsers,
      failedOnlineUsers
    );
  }, []);

  const updatedOnlineUsers = (data) => {
    let users = data.online_users;
    const local_now_time = new Date(data.now);
    const diff = local_now_time - new Date();

    const onlineUsers = localStorage.hasOwnProperty("onlineUsers")
      ? JSON.parse(localStorage.getItem("onlineUsers"))
      : [];

    onlineUsers
      .filter((user) => users.indexOf(user.id) != -1)
      .map((user) => {
        user.login_date = new Date(
          new Date(users.find((item) => item.id == user.id).login_date) - diff
        );
      });
    localStorage.setItem("onlineUsers", JSON.stringify(onlineUsers));
  };

  const failedOnlineUsers = (data) => {
    console.log("error * ", data);
  };

  const Apps = () => {
    return (
      <ul className="jr-list jr-list-half">
        <li className="jr-list-item" onClick={() => setSettings(false)}>
          <Link className="jr-list-link" to="/app/settings/profile">
            <i className="zmdi zmdi-account zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.settings.profile" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item" onClick={() => setSettings(false)}>
          <Link className="jr-list-link" to="/app/settings/company/settings">
            <i className="zmdi zmdi-laptop zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.customers.company" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item" onClick={() => setSettings(false)}>
          <Link className="jr-list-link" to="/app/settings/helpdesk">
            <i className="zmdi zmdi-help zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.helpdesk" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item" onClick={() => setSettings(false)}>
          <Link className="jr-list-link" to="/app/settings/proposal">
            <i className="zmdi zmdi-shield-check zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.proposal" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item" onClick={() => setSettings(false)}>
          <Link className="jr-list-link" to="/app/settings/users">
            <i className="zmdi zmdi-accounts zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.settings.users" />
            </span>
          </Link>
        </li>

        {/* <li className="jr-list-item" onClick={() => setSettings(false)}>
          <Link className="jr-list-link" to="/app/settings/caches">
            <i className="zmdi zmdi-refresh zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.settings.caches" />
            </span>
          </Link>
        </li> */}

        <li className="jr-list-item" onClick={() => setSettings(false)}>
          <Link className="jr-list-link" to="/app/settings/stickers">
            <i className="zmdi zmdi-comment-edit zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.stickers" />
            </span>
          </Link>
        </li>
      </ul>
    );
  };

  const onAppsSelect = () => {
    setSettings(!settings);
  };

  const onProfile = () => {
    setProfile(!profile);
  };

  const handleLogout = () => {
    onProfile();
    dispatch(userSignOut());
  };

  const handleProfile = () => {
    onProfile();
    History.push({
      pathname: `/app/settings/profile`,
    });
  };

  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? "d-block d-xl-none"
    : drawerType.includes(COLLAPSED_DRAWER)
      ? "d-block"
      : "d-none";

  return (
    <AppBar className="app-main-header">
      <Toolbar className="app-toolbar" disableGutters={false}>
        <IconButton
          className={`jr-menu-icon mr-3 ${drawerStyle}`}
          aria-label="Menu"
          onClick={onToggleCollapsedNav}
        >
          <span className="menu-icon" />
        </IconButton>

        <Link className="app-logo mr-2 d-none d-sm-block" to="/" style={{ marginLeft: sidebar_expand ? 250 : 0 }}>
          <img
            src={require("assets/images/logo.png")}
            alt="Dashboard"
            title="Dashboard"
          />
        </Link>
        <ul className="header-notifications ml-auto list-inline">
          <li className="list-inline-item">
            <Dropdown
              className="quick-menu app-notification"
              isOpen={settings}
              toggle={onAppsSelect}
            >
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown"
              >
                <span className="cursor-pointer">
                  <i className="zmdi zmdi-settings zmdi-hc-fw zmdi-hc-lg" />
                </span>
              </DropdownToggle>

              <DropdownMenu>{Apps()}</DropdownMenu>
            </Dropdown>
          </li>
          <li className="list-inline-item app-tour">
            <Dropdown
              className="quick-menu"
              isOpen={appNotification}
              toggle={onAppNotificationSelect}
            >
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown"
              >
                <IconButton className="icon-btn">
                  <i className="zmdi zmdi-notifications-none icon-alert animated infinite wobble" />
                </IconButton>
              </DropdownToggle>

              <DropdownMenu right>
                <CardHeader
                  styleName="align-items-center"
                  heading={<IntlMessages id="appNotification.title" />}
                />
                <AppNotification
                  onAppNotificationSelect={onAppNotificationSelect}
                />
              </DropdownMenu>
            </Dropdown>
          </li>

          <li className="list-inline-item app-tour">
            <Dropdown
              className="quick-menu"
              isOpen={profile}
              toggle={onProfile}
            >
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown"
              >
                <Tooltip title={nowuser.first_name}>
                  <Avatar
                    className="size-40 mr-2 cursor-pointer"
                    alt={nowuser.first_name}
                    src={`${public_url}upload/img/${nowuser.profile_pic}`}
                    style={{
                      background: nowuser.profile_pic
                        ? "transparent"
                        : StringToColor(nowuser.first_name),
                    }}
                  />
                </Tooltip>
              </DropdownToggle>

              <DropdownMenu className="profile-dropdown" right>
                <ul className="profile-list">
                  <li className="profile-list-item" onClick={handleProfile}>
                    <i className="zmdi zmdi-account zmdi-hc-lg" />
                    <span className="jr-list-text ml-2">
                      <IntlMessages id="sidebar.settings.profile" />
                    </span>
                  </li>
                  <li className="profile-list-item" onClick={handleLogout}>
                    <i className="zmdi zmdi-sign-in zmdi-hc-lg" />
                    <span className="jr-list-text ml-2">
                      <IntlMessages id="popup.logout" />
                    </span>
                  </li>
                </ul>
              </DropdownMenu>
            </Dropdown>
          </li>
        </ul>
        {snackbar_flag ? (
          <div
            className="MuiPaper-root MuiSnackbarContent-root bg-warning MuiPaper-elevation6 p-2 d-flex justify-content-between"
            role="alert"
            style={{
              position: "fixed",
              right: "40px",
              bottom: nowuser.is_feedback == "1" ? "90px" : "35px",
            }}
          >
            <div
              className="MuiSnackbarContent-message"
              onClick={() => handleRequestClose(project_id)}
              style={{ cursor: "pointer" }}
            >
              <h3 className="m-0 text-white">{message}</h3>
            </div>
            <div
              className="ml-3 mr-2 text-white"
              onClick={() => setSnackbarRead(false)}
              style={{ cursor: "pointer" }}
            >
              <i className="zmdi zmdi-close"></i>
            </div>
          </div>
        ) : null}

        <div className="ellipse-shape" />
        {nowuser.is_feedback == "1" ? (
          <Feedback
            projectId="send_feedback"
            primaryColor="#3f51b5"
            hoverBorderColor="rgb(233, 30, 99)"
          />
        ) : null}
        <Dialog
          open={modal_cache}
          onClose={() => setModalCache(false)}
          fullWidth={true}
          maxWidth="sm"
        >
          <div className="MuiDialogTitle-root bg-red accent-4 p-3 py-2">
            <h2 className="MuiTypography-root MuiTypography-h6 text-white">
              Save all caches on your local device
            </h2>
          </div>
          <DialogContent>
            <div className="row align-items-center">
              <div className="col-8 col-md-9">
                Customers
                <LinearProgress variant="determinate" value={customer_cnt} />
                <div className="text-truncate sub-heading">{customer_name}</div>
              </div>
              <div className="col-4 col-md-3">
                <CustomerCache
                  setCount={setCustomerCnt}
                  setName={setCustomerName}
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-8 col-md-9">
                Projects
                <LinearProgress variant="determinate" value={project_cnt} />
                <div className="text-truncate sub-heading">{project_name}</div>
              </div>
              <div className="col-4 col-md-3">
                <ProjectCache
                  setCount={setProjectCnt}
                  setName={setProjectName}
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-8 col-md-9">
                Locations
                <LinearProgress variant="determinate" value={location_cnt} />
                <div className="text-truncate sub-heading">{location_name}</div>
              </div>
              <div className="col-4 col-md-3">
                <LocationCache
                  setCount={setLocationCnt}
                  setName={setLocationName}
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-8 col-md-9">
                Products
                <LinearProgress variant="determinate" value={product_cnt} />
                <div className="text-truncate sub-heading">{product_name}</div>
              </div>
              <div className="col-4 col-md-3">
                <ProductCache
                  setCount={setProductCnt}
                  setName={setProductName}
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-8 col-md-9">
                Tasks
                <LinearProgress variant="determinate" value={task_cnt} />
                <div className="text-truncate sub-heading">{task_name}</div>
              </div>
              <div className="col-4 col-md-3">
                <TaskCache setCount={setTaskCnt} setName={setTaskName} />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-8 col-md-9">
                Other files
                <LinearProgress variant="determinate" value={js_cnt} />
                <div className="text-truncate sub-heading">{js_name}</div>
              </div>
              <div className="col-4 col-md-3">
                <JavascriptCache setCount={setJsCnt} setName={setJsName} />
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Index);
